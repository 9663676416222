<template>
  <div class="report-business-attributes">
    <h2 class="mb-0 pb-2 font-weight-semi-bold">Business attributes</h2>
    <AuditDescription class="mb-4">
      Amenities that businesses offer in addition to their primary services, like a restaurant offereing free wifi or live music.
    </AuditDescription>
    <p class="text-muted pb-2">Only unique business attributes are analyzed.</p>
    <div class="report-business-attributes__legend competitors-chart-legend">
      <div class="competitors-chart-legend-item">
        <div class="point green" />
        Matched with my location
      </div>
      <div class="competitors-chart-legend-item">
        <div class="point blue" />
        Competitors matches
      </div>
    </div>

    <VueSlimTable
      ref="table"
      :source="() => businessAttributes"
      :columns="columns"
      class="report-business-attributes__table responsive-table borderless-table audit-table">
      <template #row="{ row, columns: tableColumns }">
        <tr>
          <td class="column">
            {{ row.groupName }}
          </td>
          <td v-for="(attributes, colIndex) in row.columns" :column-caption="tableColumns[colIndex + 1].title"
            class="column"
            :class="{'align-top': attributes?.length}"
            :key="`${row.groupName}${colIndex}`">
            <div v-if="attributes?.length" class="report-business-attributes__list">
              <span v-for="attribute in attributes"
                :key="`${attribute.name}${colIndex}`"
                class="report-business-attributes__item"
                :class="{
                  'report-business-attributes__item--green': attribute.typeOfMatches === 1,
                  'report-business-attributes__item--blue': attribute.typeOfMatches === 2
                }">
                <i v-if="attribute.absent" class="fa-solid fa-light fa-ban" />
                {{ attribute.name }}
              </span>
            </div>
            <div v-else>-</div>
          </td>
        </tr>
      </template>
    </VueSlimTable>
  </div>
</template>
<script>
export default {
  props: {
    sectionData: {
      type: Object,
      required: true
    }
  },
  created() {
    this.columns = [
      {
        title: 'Attributes'
      },
      {
        title: 'My location'
      }
    ]

    this.businessAttributes = []

    const { competitors, current } = this.sectionData
    const allBusinesses = [{ businessAttributes: current }, ...competitors]
    const attributeGroupNames = new Set(allBusinesses.reduce((acc, competitor) => {
      competitor.businessAttributes.forEach((attribute) => {
        acc.push(attribute.groupName)
      })

      return acc
    }, []).sort())

    competitors.forEach((competitor) => {
      this.columns.push({
        title: competitor.name
      })
    })

    attributeGroupNames.forEach((groupName) => {
      const row = {
        groupName,
        columns: []
      }

      allBusinesses.forEach((competitor) => {
        const attributes = competitor.businessAttributes.filter((attribute) => groupName === attribute.groupName)

        row.columns.push(attributes.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }

          if (a.name > b.name) {
            return 1
          }

          return 0
        }))
      })

      this.businessAttributes.push(row)
    })
  }
}
</script>
