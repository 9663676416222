import Vue from 'vue'
import toastr from 'toastr'
import VueWebhooksSettings from 'vue_widgets/organizations/webhooks_settings'
import { DEFAULT_ERROR_MESSAGE } from '../common/constants'
import { collectErrors } from '../common/rails'

function serializeForm(href, $form) {
  return new FormData($form.get(0))
}

// eslint-disable-next-line no-unused-vars

function loadEvents(params, href, $modal) {
  const $submit = $modal.find('.btn-primary')
  const $form = $modal.find('form')
  $form.submit((e) => {
    e.preventDefault()

    $form.toggleWrapper()

    $.ajax({
      type: 'patch',
      url: $form.attr('action'),
      data: serializeForm(href, $form),
      processData: false,
      contentType: false,
      success: () => { window.location.reload() },
      error: (xhr) => {
        $submit.attr('disabled', false)
        $form.toggleWrapper({}, false)
        if (xhr.responseJSON && xhr.responseJSON.template) {
          const $template = $(xhr.responseJSON.template).find('.modal-body')
          $modal.find('.modal-body').replaceWith($template)
        } else {
          toastr.error(collectErrors(xhr.responseJSON.error_message) || 'Something is wrong. Please try later.')
        }
      }
    })
  })
}

// TODO: refactor this functions
function toggleOrganizationSetting(apiLocationPath) {
  const ajaxSettings = {
    checked: {
      successMessage: 'Setting was enabled.',
      url: `${apiLocationPath}`
    },
    unchecked: {
      successMessage: 'Setting was disabled.',
      url: `${apiLocationPath}`
    },
    errorMessage: 'Something went wrong! Please try again later.'
  }

  $('.js-organization-settings-checkbox').on('change', (e) => {
    const $target = $(e.currentTarget)
    const state = $target.prop('checked') ? 'checked' : 'unchecked'
    const param = ajaxSettings[state]
    const patchParam = {}
    patchParam[$target[0].name] = state === 'checked' ? 1 : 0
    $target.prop('disabled', true)
    $('.alert-info').toggleClass('d-none')

    $.ajax2({
      method: 'PATCH',
      url: param.url,
      data: patchParam,
      error: (res) => {
        const message = (res.responseJSON && res.responseJSON.error) || ajaxSettings.errorMessage
        toastr.warning(message)
        $target.prop('checked', !$target.prop('checked'))
      },
      success: () => {
        toastr.success(param.successMessage)
      },
      complete: () => {
        $target.prop('disabled', false)
      }
    })
  })
}

Styxie.Initializers.Organizations = {
  admin_page: (params) => {
    const ICON_CLASSES = ['fa-spinner', 'fa-pulse', 'fa-sliders-up']
    $(() => {
      toggleOrganizationSetting(params.apiLocationPath)
      $('.js-edit-control').click((e) => {
        e.preventDefault()
        const target = e.currentTarget
        const icon = target.querySelector('i')
        ICON_CLASSES.forEach((c) => icon.classList.toggle(c))
        target.classList.add('disabled')

        $.ajax({
          url: target.href,
          success: (res) => {
            const $modal = $(res.template)
            $('body').append($modal)
            $modal.one('hidden.bs.modal', () => { $modal.remove() })
            $modal.modal('show')
            loadEvents(params, target.href, $modal)
            $("[data-use='select2']").each(function chooser() {
              const $this = $(this)
              $this.select2({
                width: '100%',
                minimumInputLength: 3,
                allowClear: true,
                placeholder: '',
                ajax: {
                  url: $this.data('source'),
                  data: (prms) => ({ q: prms.term, page: prms.page || 1 }),
                  processResults: (data) => ({
                    results: data.results,
                    pagination: {
                      more: (data.page * 1) < data.count_filtered
                    }
                  })
                }
              })
            })
          },
          complete: () => {
            target.classList.remove('disabled')
            ICON_CLASSES.forEach((c) => icon.classList.toggle(c))
          },
          error: () => {
            toastr.error(DEFAULT_ERROR_MESSAGE)
          }
        })
      })
    })
  },
  gds_integration: ({ apiLocationPath }) => {
    $(() => { toggleOrganizationSetting(apiLocationPath) })
  },
  webhooks_settings: (props) => {
    $(() => {
      /* eslint-disable no-new */
      new Vue({
        el: document.getElementById('vue-webhooks-settings'),
        render: (h) => h(VueWebhooksSettings, { props })
      })
      /* eslint-disable no-new */
    })
  }
}
