<template>
  <div :class="['report-page', { 'py-3': !hasPropertisData(parsedData, mainProps) }]">
    <div class="reports-content">
      <div class="mb-4">
        <div v-if="!isPublic" class="mt-n5 pt-3 pb-2 noprint">
          <a href="/reports" class="d-inline-flex align-items-center gap-1">
            <i class="far fa-long-arrow-left font-size-20" />
            Back to Reports
          </a>
        </div>
        <div class="d-flex gap-4 flex-column flex-lg-row align-items-lg-center">
          <h1 class="h2 m-0 font-weight-semi-bold color-dark">
            <div v-if="chosenLocation">
              {{ chosenLocation.name }}
              <a v-if="!isPublic" :href="chosenLocation.url" class="d-inline-block ml-3 font-size-16 align-middle noprint" target="_blank">
                <i class="far fa-arrow-up-right-from-square" />
              </a>
            </div>
            <template v-else>
              All locations
            </template>
          </h1>
          <div v-if="!isPublic" class="d-flex gap-2 ml-auto noprint">
            <a v-if="statsReportUrl" :href="statsReportUrl" target="_blank" class="btn border-0 font-weight-semi-bold gap-2 rounded">
              <i class="far fa-link" />
              Get Link
            </a>
            <BDropdown right toggle-class="btn border-0 font-weight-semi-bold gap-2 rounded">
              <template #button-content>
                <i class="far fa-bell" />
                Notify
                <i class="far fa-chevron-down" />
              </template>
              <a :href="notifyTeamUrl" :class="notifyDropdownClasses(notifyTeamUrl)" @click.prevent="sendEmailNotification">
                <i class="far fa-envelope" />
                Notify Team Members
              </a>
              <a :href="notifyCustomerUrl" :class="notifyDropdownClasses(notifyCustomerUrl)" @click.prevent="sendEmailNotification">
                <i class="far fa-envelope" />
                Notify Customer
              </a>
            </BDropdown>
          </div>
        </div>

        <PrintHint class="noprint" />
      </div>

      <div v-if="chosenLocation" class="color-dark font-weight-semi-bold">
        <div v-if="chosenLocation.storeCode" class="report-location-description">
          <span class="text-muted">Store code:</span>
          {{ chosenLocation.storeCode }}
        </div>
        <div v-if="chosenLocation.formattedAddress" class="report-location-description">
          <span class="text-muted">Address:</span>
          {{ chosenLocation.formattedAddress }}
        </div>
        <div class="report-location-description">
          <span class="text-muted">Comparing:</span>
          {{ chosenLocationFormattedDate.period.start }} - {{ chosenLocationFormattedDate.period.end }} VS
          {{ chosenLocationFormattedDate.period.compareStart }} - {{ chosenLocationFormattedDate.period.compareEnd }}
        </div>
      </div>

      <ol v-else class="report-page-location-list">
        <li v-for="{ id, name, storeCode, formattedAddress, url, period, locationType } in locationsWithFormattedDates" :key="id">
          <div class="d-flex flex-column">
            <span v-if="isPublic" class="font-weight-bold">{{ name }}</span>
            <a v-else :href="url" target="_blank" class="font-weight-bold">{{ name }}</a>
            <span v-if="locationType === 'sab'" class="text-muted">
              Service Area Business
            </span>
            <span v-else-if="formattedAddress" class="text-muted ">{{ formattedAddress }}</span>
            <span class="text-muted">{{ storeCode }}</span>
            <span class="d-block font-weight-semi-bold color-dark">
              {{ period.start }} - {{ period.end }} VS {{ period.compareStart }} - {{ period.compareEnd }}
            </span>
          </div>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { formatDate } from 'common/datepicker'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import { BDropdown } from 'bootstrap-vue'
import { mainProps, hasPropertisData } from './helpers'
import PrintHint from '../../components/print_hint'

const dropdownClasses = 'dropdown-item d-flex align-items-center gap-2 font-weight-semi-bold'

export default {
  components: {
    PrintHint, BDropdown
  },
  computed: {
    ...mapState([
      'chosenLocation',
      'locations',
      'statsReportUrl',
      'notifyTeamUrl',
      'notifyCustomerUrl',
      'parsedData',
      'isPublic'
    ]),
    chosenLocationFormattedDate() {
      return {
        ...this.chosenLocation,
        period: this.formatPeriod(this.chosenLocation.period)
      }
    },
    locationsWithFormattedDates() {
      return this.locations.map((l) => ({
        ...l,
        period: this.formatPeriod(l.period)
      }))
    }
  },
  created() {
    this.mainProps = mainProps
  },
  methods: {
    notifyDropdownClasses(url) {
      const specailClass = url ? 'text-primary' : 'disabled'

      return `${dropdownClasses} ${specailClass}`
    },
    formatPeriod(period) {
      return {
        start: formatDate(period.start, 'MMM D, YYYY'),
        end: formatDate(period.end, 'MMM D, YYYY'),
        compareStart: formatDate(period.compareStart, 'MMM D, YYYY'),
        compareEnd: formatDate(period.compareEnd, 'MMM D, YYYY')
      }
    },
    sendEmailNotification(e) {
      axios.post(e.target.getAttribute('href'))
        .then((success) => toastr.success(success.data.msg))
        .catch((error) => toastr.error(error.response?.data?.msg || DEFAULT_ERROR_MESSAGE))
    },
    hasPropertisData
  }
}
</script>
