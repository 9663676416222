import { App } from 'common/rails'
import camelCaseKeys from 'camelcase-keys-deep'

const handleUrl = (csvUrl) => {
  const loaderElement = document.getElementById('js-csv-loader')
  loaderElement.classList.add('d-none')

  const csvLink = document.getElementById('js-csv-link')
  csvLink.setAttribute('href', csvUrl)
  csvLink.setAttribute('download', csvUrl.split('/')[csvUrl.split('/').length - 1])
  csvLink.classList.remove('d-none')
  csvLink.click()

  window.close()
}

Styxie.Initializers.Downloads = {
  show: ({ csvUrl, jid }) => {
    $(() => {
      if (csvUrl) {
        handleUrl(csvUrl)
      } else {
        App.cable.subscriptions.create('CsvExportChannel', {
          received(msg) {
            const message = camelCaseKeys(msg)
            if (jid === message.jid) {
              handleUrl(message.csvUrl)
            }
          }
        })
      }
    })
  }
}
