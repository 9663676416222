<template>
  <div>
    <h2 class="mb-2 font-weight-semi-bold">JSON-LD</h2>
    <AuditDescription class="mb-4">
      JSON data gives search engines a bare bones breakdown of your site's contents. If you think of your website as a song, JSON would be the sheet music.
    </AuditDescription>

    <!--<div class="widget mb-2">
      <div ref="ld-json-chart" class="pie-chart" />
      </div>-->

    <div class="json-ld">
      <div class="json-ld__business">
        <div class="json-ld__name json-ld__name--current">
          <i v-if="!business.json" class="fas fa-info-circle color-warning" />
          <span>My location</span>
          <a v-if="business.website"
            :href="business.website"
            class="pl-2"
            target="_blank">
            <i class="fa-regular fa-arrow-up-right-from-square" />
          </a>
        </div>
        <div v-if="business.json" class="json-ld__json json-ld__json--current">
          <pre class="scroll ver hor">{{ business.json }}</pre>
          <button class="button-copy" @click="onCopy($event, business.json)">
            <i class="far fa-fw fa-copy" />
          </button>
        </div>
        <JsonLdNotFoundMessage v-else :status="business.status" modifier="current" />
      </div>
      <div class="json-ld__competitors">
        <div ref="listNode" class="json-ld__list">
          <div class="json-ld__competitors-nav">
            <button ref="buttonNode" v-if="!isFirstCompetitorVisible" class="json-ld__nav-item json-ld__nav-item--prev"
              @click="scrollCompetitors(-1)">
              <i class="fa-solid fa-chevron-left" />
            </button>
            <button v-if="!isLastCompetitorVisible"
              class="json-ld__nav-item json-ld__nav-item--next" @click="scrollCompetitors(1)">
              <i class="fa-solid fa-chevron-right" />
            </button>
          </div>
          <div v-for="(competitor, index) in competitors" ref="competitorNode" class="json-ld__name"
            :class="{ active: index === activeTabIndex }"
            :style="index === 0 ? { marginLeft: `${marginLeft}px` } : null"
            @click="activeTabIndex = index" :key="index">
            <i v-if="!competitor.json" class="fas fa-info-circle color-warning" />
            <span>{{ competitor.name }}</span>
            <a v-if="competitor.website"
              :href="competitor.website"
              class="pl-2"
              target="_blank">
              <i class="fa-regular fa-arrow-up-right-from-square" />
            </a>
          </div>
        </div>
        <div ref="competitorJsonNode" v-if="activeCompetitor.json" class="json-ld__json json-ld__json--competitors">
          <pre class="scroll ver hor">{{ activeCompetitor.json }}</pre>
          <button class="button-copy" @click="onCopy($event, activeCompetitor.json)">
            <i class="far fa-fw fa-copy" />
          </button>
        </div>
        <JsonLdNotFoundMessage v-else :status="activeCompetitor.status" modifier="competitors" />
      </div>
    </div>
  </div>
</template>

<script>
// import Highcharts from 'highcharts'
import { copyToClipboardWithAnimation } from 'common/copy_to_clipboard'
import JsonLdNotFoundMessage from './json_ld/not_found_message.vue'

export default {
  components: {
    JsonLdNotFoundMessage
  },
  props: {
    sectionData: { type: Object, required: true }
  },
  data: () => ({
    marginLeft: 0,
    activeScrollPosition: 0,
    activeTabIndex: 0,
    isFirstCompetitorVisible: true,
    isLastCompetitorVisible: true
  }),
  created() {
    const { sectionData } = this

    // this function attempts to find most top-level @type declaration in ld-json schema
    const digUpTheType = (object) => {
      if (!object) {
        return null
      }

      if (object['@type']) {
        return object['@type']
      }

      const keys = Object.keys(object)

      for (let i = 0; i < keys.length; i += 1) {
        const value = object[keys[i]]

        if (Array.isArray(value)) {
          const type = digUpTheType(value)

          if (type) {
            return type
          }
        }
      }

      return null
    }

    const formatData = (business) => {
      const { json } = business
      let jsonString = ''
      const jsonTypes = []

      json?.forEach((jsonSchemaObject) => {
        let jsonToStringify = jsonSchemaObject
        let jsonIsValid = true

        if (typeof jsonSchemaObject === 'string') {
          try {
            jsonToStringify = JSON.parse(jsonSchemaObject)
          } catch {
            jsonIsValid = false
          }
        }

        if (!jsonIsValid) {
          // eslint-disable-next-line no-useless-escape
          jsonString += `\n\nThe following json ld schema is not valid:\n\n<script type="application/ld+json">\n${jsonSchemaObject}\n<\/script>\n`
          return
        }

        const formattedJson = JSON.stringify(jsonToStringify, null, 2)
        const type = digUpTheType(jsonToStringify)
        if (type) {
          jsonTypes.push(type)
        }
        if (formattedJson) {
          // if we remove this 'useless' escape, webpack wont compile
          // eslint-disable-next-line no-useless-escape
          jsonString += `<script type="application/ld+json">\n${formattedJson}\n<\/script>\n`
        }
      })

      return {
        ...business,
        json: jsonString, // prettified json for output
        types: jsonTypes
      }
    }

    this.business = formatData({ ...sectionData.current, name: 'My location' })
    this.competitors = sectionData.competitors.map((competitor) => formatData(competitor))

    /* const noJsonLd = 'No JSON-LD'
    this.chartData = Object.entries([this.business, ...this.competitors].reduce((mem, { types }) => {
      types.forEach((type) => {
        const defType = type || noJsonLd

        if (!mem[defType]) mem[defType] = 0
        mem[defType] += 1
      })

      return mem
    }, {}))

    // this part of code is responsible for "no ld-json" part of the chart always being at the end
    const noJsonLdItem = this.chartData.find((item) => item[0] === noJsonLd)
    if (noJsonLdItem) {
      this.chartData.splice(this.chartData.indexOf(noJsonLdItem), 1)

      this.chartData.push(noJsonLdItem)
    }

    this.chartData = this.chartData.map(([name, y]) => ({ name, y })) */
  },
  mounted() {
    this.setCompetitorVisibility()
    window.addEventListener('resize', this.setCompetitorVisibility)
  },
  destroyed() {
    window.removeEventListener('resize', this.setCompetitorVisibility)
  },
  /* mounted() {
    this.chart = Highcharts.chart(
      this.$refs['ld-json-chart'],
      {
        chart: {
          type: 'pie',
          spacing: [0, 0, 0, 0],
          backgroundColor: 0
        },
        credits: { enabled: false },
        title: { text: '' },
        plotOptions: {
          pie: {
            showInLegend: true
          }
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          lineHeight: 32,
          x: 0
        },
        tooltip: {
          enabled: true,
          borderWidth: 0,
          borderRadius: 0,
          padding: 0,
          formatter() {
            return `<strong>${Math.round(this.point.percentage)}%</strong>`
          },
          useHTML: true,
          shadow: false,
          className: 'pie-chart-tooltip'
        },
        series: [{
          borderWidth: 3,
          dataLabels: {
            enabled: true,
            formatter() {
              if (this.point.percentage < 10) {
                return ''
              }

              return `<span style="color: ${this.point.color}">${Math.round(this.point.percentage)}%</span>`
            },
            connectorColor: 'transparant',
            distance: -85,
            className: 'data-label-text',
            useHTML: true,
            y: -5
          },
          data: this.chartData,
          ignoreHiddenPoint: false,
          innerSize: '65%',
          states: {
            hover: { enabled: false },
            inactive: { enabled: false }
          }
        }],
        responsive: {
          rules: [{
            condition: { maxWidth: 992 },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal'
              }
            }
          }]
        }
      }
    )
  }, */
  computed: {
    activeCompetitor() {
      return this.competitors[this.activeTabIndex]
    }
  },
  methods: {
    scrollCompetitors(direction) {
      this.activeScrollPosition += direction
      const relatedCompetitorNode = this.$refs.competitorNode[this.activeScrollPosition]

      this.$nextTick(() => {
        let buttonOffset = 0

        if (this.activeScrollPosition !== 0 && !this.isFirstCompetitorVisible) {
          buttonOffset = this.$refs.buttonNode.getBoundingClientRect().width
        }

        this.marginLeft -= relatedCompetitorNode.offsetLeft + buttonOffset * -1

        this.$nextTick(this.setCompetitorVisibility)
      })
    },
    onCopy(e, jsonldCode) {
      copyToClipboardWithAnimation({ element: e.currentTarget, textToCopy: jsonldCode })
    },
    setCompetitorVisibility() {
      const firstCompetitorNode = this.$refs.competitorNode[0]
      const lastCompetitorNode = this.$refs.competitorNode[this.$refs.competitorNode.length - 1]
      const container = this.$refs.listNode
      this.isLastCompetitorVisible = container.getBoundingClientRect().right >= lastCompetitorNode.getBoundingClientRect().right
      this.isFirstCompetitorVisible = container.getBoundingClientRect().left <= firstCompetitorNode.getBoundingClientRect().left
    }
  },
  watch: {
    activeTabIndex() {
      this.$nextTick(() => {
        this.$refs.competitorJsonNode?.scrollTo({
          top: 0
        })
      })
    }
  }
}
</script>
