import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

function defaultError() {
  toastr.error(DEFAULT_ERROR_MESSAGE)
}

export const BLANK_EXIFS = {
  xptitle: '',
  imagedescription: '',
  xpcomment: '',
  xpkeywords: '',
  xpsubject: '',
  datetime: '',
  datetimeoriginal: '',
  datetimedigitized: '',
  artist: '',
  ownername: '',
  copyright: '',
  make: '',
  model: '',
  software: '',
  latitude: '',
  longitude: ''
}

export const RECOMMENDED_EXIFS = {
  xptitle: '{{ location.name }}',
  imagedescription: '{{ location.description }}',
  xpcomment: '',
  xpkeywords: '',
  xpsubject: '',
  datetime: '{{ "now" | date: "%Y:%m:%d %H:%M:%S" }}',
  datetimeoriginal: '{{ "now" | date: "%Y:%m:%d %H:%M:%S" }}',
  datetimedigitized: '{{ "now" | date: "%Y:%m:%d %H:%M:%S" }}',
  artist: '',
  ownername: '',
  copyright: '{{ location.name }}',
  make: '',
  model: '',
  software: '',
  latitude: '{{ location.latitude }}',
  longitude: '{{ location.longitude }}'
}

export default function makeImageSelectStore(params) {
  const hasImage = params.object.imagePath
  let selectedImageObj = null
  if (params.object.imageId) {
    selectedImageObj = { id: params.object.imageId, url: params.object.imagePath, source: 'gallery' }
  }

  return {
    state: {
      imageStep: hasImage ? 'advanced' : 'select',
      imageSelectTab: 'upload',
      galleryImages: [],
      galleryImagesFetching: false,
      selectedImage: selectedImageObj,
      selectedImageUploading: false,
      imageSourceExifs: hasImage ? params.object.exifs : { ...BLANK_EXIFS },
      imageCurrentExifs: hasImage ? params.object.exifs : { ...BLANK_EXIFS },
      imagePreviewExifs: { ...BLANK_EXIFS },
      imagePreviewErrors: {},
      exifState: 'keep',
      selectedImageFetchingExifs: false,
      selectedGalleryTags: [],
      ...params
    },
    actions: {
      fetchGalleryImages({ commit, state }, page) {
        commit('changeGalleryImagesFetching', true)
        const prms = { page, per: 20 }
        if (state.selectedGalleryTags.length) {
          prms.tagged_with = state.selectedGalleryTags
        }

        axios.get('/api/images', { params: prms })
          .then(
            (res) => {
              const mutation = page === 1 ? 'replaceGalleryImages' : 'addGalleryImages'
              commit(mutation, res.data)
            },
            defaultError
          )
          .finally(() => commit('changeGalleryImagesFetching', false))
      },
      changeSelectedImage({ commit, dispatch }, selectedImage) {
        commit('changeSelectedImage', selectedImage)
        if (selectedImage) {
          dispatch('fetchCurrentExifs', selectedImage)
        }
      },
      fetchCurrentExifs({ dispatch, commit }, selectedImage) {
        let request
        if (selectedImage.source === 'upload') {
          const data = new FormData()
          data.append('gmb_media[file]', selectedImage.file)
          request = axios.post('/api/gmb/media/extract_exifs', data)
        } else if (selectedImage.source === 'gallery') {
          request = axios.get(`/api/images/${selectedImage.id}/exifs`)
        }

        commit('changeSelectedImageFetchingExifs', true)
        if (request) {
          request.then(
            (res) => {
              commit('changeSelectedImageFetchingExifs', false)
              commit('replaceCurrentExifs', { ...BLANK_EXIFS, ...res.data })
              commit('changeImageSourceExifs', { ...BLANK_EXIFS, ...res.data })
              dispatch('fetchExifPreview')
            },
            defaultError
          )
        }
      },
      replaceCurrentExifs({ state, commit, dispatch }, value) {
        if (value === 'lv-recommended') {
          commit('replaceCurrentExifs', RECOMMENDED_EXIFS)
          commit('changeExifState', 'edit')
        } else if (value === 'current') {
          commit('replaceCurrentExifs', state.imageSourceExifs)
          commit('changeExifState', 'keep')
        } else {
          const template = state.exifTemplates.find((t) => t.id === value)
          if (template) {
            commit('replaceCurrentExifs', template.exifs)
            commit('changeExifState', 'edit')
          }
        }

        dispatch('fetchExifPreview')
      },
      cleanCurrentExifs({ commit, dispatch }) {
        commit('changeExifState', 'clean')
        commit('replaceCurrentExifs', BLANK_EXIFS)
        dispatch('fetchExifPreview')
      },
      changeCurrentExif({ dispatch, commit }, { identifier, value }) {
        commit('changeExifState', 'edit')
        commit('changeCurrentExif', { identifier, value })
        dispatch('fetchExifPreview')
      },
      fetchExifPreview({ state, commit, getters }) {
        if (getters.selectedSource && state.selectedImage) {
          const query = { fields: state.imageCurrentExifs, entity: getters.selectedSource.entity, entity_type: getters.selectedSource.type }
          axios.post('/api/gmb/preview/exif', query)
            .then(
              (success) => {
                commit('updatePreviewExifs', success.data)
              },
              (error) => {
                toastr.error(error.response?.data?.error || DEFAULT_ERROR_MESSAGE)
              }
            )
        }
      },
      imageSelected({ state, commit }) {
        if (state.selectedImage.source === 'gallery') {
          commit('changeGalleryImage', state.selectedImage)
          return
        }

        const data = new FormData()
        if (state.selectedImage.source === 'upload') {
          data.append('file', state.selectedImage.file, state.selectedImage.file.name)
        }

        commit('changeSelectedImageUploading', true)
        axios.post('/api/images', data)
          .then((res) => commit('changeGalleryImage', res.data))
          .finally(() => commit('changeSelectedImageUploading', false))
      },
      fetchExifTemplates({ commit }) {
        axios.get('/api/exif_templates/index_for_select', { params: { with_exifs: true } }).then((res) => {
          commit('changeExifTemplates', res.data)
        })
      },
      toggleGalleryTag({ state, commit, dispatch }, tag) {
        const selected = state.selectedGalleryTags.includes(tag)
        const newTags = selected ? state.selectedGalleryTags.filter((t) => t !== tag) : [...state.selectedGalleryTags, tag]
        commit('changeSelectedGalleryTags', newTags)
        dispatch('fetchGalleryImages', 1)
      }
    },
    mutations: {
      changeSelectedGalleryTags(state, tags) {
        state.selectedGalleryTags = tags
      },
      changeExifTemplates(state, templates) {
        state.exifTemplates = templates
      },
      changeSelectedImageFetchingExifs(state, bool) {
        state.selectedImageFetchingExifs = bool
      },
      changeImageSelectTab(state, tab) {
        state.imageSelectTab = tab
      },
      changeImageStep(state, step) {
        state.imageStep = step
      },
      replaceGalleryImages(state, images) {
        state.galleryImages = images
      },
      addGalleryImages(state, images) {
        state.galleryImages = [...state.galleryImages, ...images]
      },
      changeSelectedImage(state, payload) {
        // can be galleryImg, uploaded file or null
        state.selectedImage = payload
        state.imageStep = payload ? 'advanced' : 'select'
      },
      changeCurrentExif(state, { identifier, value }) {
        state.imageCurrentExifs[identifier] = value
      },
      updatePreviewExifs(state, { fields, errors }) {
        state.imagePreviewExifs = fields
        state.imagePreviewErrors = errors
      },
      replaceCurrentExifs(state, exifs) {
        state.imageCurrentExifs = { ...exifs }
      },
      changeExifState(state, exifState) {
        state.exifState = exifState
      },
      changeGalleryImage(state, image) {
        state.object.deleteImage = false
        state.object.galleryImage = image
      },
      changeGalleryImagesFetching(state, bool) {
        state.galleryImagesFetching = bool
      },
      changeSelectedImageUploading(state, bool) {
        state.selectedImageUploading = bool
      },
      changeImageSourceExifs(state, exifs) {
        state.imageSourceExifs = exifs
      },
      removeSelectedImage(state) {
        state.object.galleryImage = null
        state.object.imagePath = null
        state.object.deleteImage = true
        state.selectedImage = null
        state.imageSourceExifs = { ...BLANK_EXIFS }
        state.imageCurrentExifs = { ...BLANK_EXIFS }
        state.imagePreviewExifs = { ...BLANK_EXIFS }
        state.imagePreviewErrors = {}
        state.exifState = 'keep'
        state.imageStep = 'select'
      }
    }
  }
}
