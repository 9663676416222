<template>
  <div class="form-field form-field--rem form-field--reset-appearance" :class="{ error, disabled }">
    <label class="d-block m-0">
      <span class="m-0 d-block" v-if="label">{{ label }}</span>
      <input
        ref="input"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="handleInput"
        @blur="$emit('blur', $event)">
    </label>
    <div v-if="error" class="text-danger">{{ errorText }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    label: {
      type: String,
      required: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    errorText: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    handleInput(event) {
      if (this.disabled) {
        return
      }

      let newValue = event.target.value

      if (this.type === 'number') {
        const parsedValue = parseFloat(newValue)

        if (/(^[0-9]+$|^$)/.test(newValue)) {
          newValue = Number.isNaN(parsedValue) ? '' : parsedValue
        } else {
          newValue = this.value
          this.$refs.input.value = newValue
        }
      }

      if (newValue !== this.value) {
        this.$emit('input', newValue)
      }
    },
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>
