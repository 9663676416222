<template>
  <div>
    <div class="d-flex align-items-center gap-2 text-dark mb-2">
      <span class="font-size-18 font-weight-semi-bold">Video</span>
    </div>
    <div :class="['mv-image-box-container', { disabled: isDisabled, dragstart: onDragItem && !isDisabled }]"
      @dragover.prevent
      @dragenter.prevent
      @drop="cancelDrop">
      <div v-for="(video, index) in videos" :key="index"
        class="mv-image-box"
        ref="mv-video-box"
        draggable
        @dragstart="onDragstart($event, video)"
        @dragover.prevent.stop="onDragover($event, video)"
        @drop="onDrop($event, video, 0)">
        <video :src="video.objectUrl || video.sourceUrl" class="w-100 h-100" />
        <div v-if="video.state" :class="['mv-image-box-state', video.state]">
          <i :class="videoStatus(video.state).iconClass" />
          {{ videoStatus(video.state).label }}
          <a v-if="video.mediaUrl" :href="video.mediaUrl" target="_blank" class="ml-auto">
            <i class="far fa-link" />
          </a>
        </div>
        <div class="mv-image-box-play-icon" @click="openVideo(video.objectUrl || video.sourceUrl)">
          <i class="far fa-play" />
        </div>
        <button v-if="!isDisabled" type="button" @click="removeVideo(index)" />
        <div ref="drop-catch-field" class="drop-catch-field left" @dragover="showPosition($event)" @drop.prevent.stop="onDrop($event, video, 0)" />
        <div ref="drop-catch-field" class="drop-catch-field right" @dragover="showPosition($event)" @drop.prevent.stop="onDrop($event, video, 1)" />
      </div>
      <div v-for="({ progress }, index) in pseudoVideos" :key="`pseudo${index}`" class="mv-image-box pseudo">
        <i class="far fa-spinner-third fa-spin font-size-20" />
        {{ progress }}
      </div>

      <div v-if="videos.length < MAX_FILES" class="mv-image-btn">
        <input type="file"
          ref="file"
          multiple
          :accept="acceptTypes"
          :disabled="isDisabled"
          @change="onFileChange">
        <div class="mv-image-btn-label">
          <i class="far fa-plus-circle mb-1" />
          Add video
        </div>
      </div>

      <b-modal ref="video-modal" hide-footer title="View video">
        <video :src="openedVideoUrl" class="modal-video" controls autoplay />
        <div class="d-flex justify-content-end">
          <button class="btn btn-primary rounded" @click="closeVideo">Close</button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
const MAX_FILES = 5

export default {
  name: 'MomentVideo',
  props: {
    checkin: { type: Object, required: true },
    isRequestSent: { type: Boolean, default: false },
    acceptTypes: { type: String, default: 'video/mp4, video/x-matroska, video/webm, video/quicktime, .mov, .mkv' }
  },
  data() {
    return {
      videos: this.checkin.videoData,
      onDragItem: null,
      openedVideoUrl: '',
      pseudoVideos: []
    }
  },
  created() {
    this.MAX_FILES = MAX_FILES
    this.uploadUrl = '/gb/ajax/moments/video_upload_url'
  },
  computed: {
    isDisabled() {
      return this.checkin.aasmState !== 'pending' || this.isRequestSent
    }
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        return
      }

      const wrongFormatFiles = []
      const filteredFiles = Array.from(files).filter(({ name, type }) => {
        if (this.acceptTypes.includes(type)) return true

        wrongFormatFiles.push(name)
        return false
      }).slice(0, MAX_FILES - this.videos.length)

      if (wrongFormatFiles.length > 0) {
        toastr.warning(`Can't upload files:<br>${wrongFormatFiles.join('<br>')}`, { timeOut: 2000 })
      }

      this.$refs.file.value = ''
      if (!/safari/i.test(navigator.userAgent)) {
        this.$refs.file.type = ''
        this.$refs.file.type = 'file'
      }

      this.uploadVideos(filteredFiles)
    },
    uploadVideos(files) {
      this.$emit('onFileUpload', true)
      const requestsForUploadLinks = files.map((file) => axios.get(this.uploadUrl, { params: { filename: file.name } }))
      Promise.allSettled(requestsForUploadLinks)
        .then((responses) => {
          const requestsToUploadFiles = []
          const sentFiles = []
          const filePaths = []
          responses.forEach((response, index) => {
            if (response.status === 'fulfilled') {
              this.pseudoVideos.push({
                fileIndex: index,
                progress: '0%'
              })
              requestsToUploadFiles.push(axios.put(response.value.data.upload_url, files[index], {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Accept: 'application/json'
                },
                onUploadProgress: (progressEvent) => {
                  const pseudoVideosIndex = this.pseudoVideos.findIndex((v) => v.fileIndex === index)
                  this.pseudoVideos[pseudoVideosIndex].progress = `${Math.round(progressEvent.loaded / progressEvent.total * 100)}%`
                }
              }))

              filePaths.push(response.value.data.upload_file_path)
              sentFiles.push(files[index])
            } else {
              toastr.error(`Unable to upload file "${files[index].name}"`)
            }
          })

          Promise.allSettled(requestsToUploadFiles)
            .then((awsResponses) => {
              awsResponses.forEach((response, index) => {
                if (response.status === 'fulfilled') {
                  const pseudoVideosIndex = this.pseudoVideos.findIndex((v) => v.fileIndex === index)
                  this.pseudoVideos.splice(pseudoVideosIndex, 1)
                  this.videos.push({
                    sourceUrl: filePaths[index],
                    objectUrl: URL.createObjectURL(sentFiles[index]),
                    state: null
                  })
                } else {
                  toastr.error(`Unable to upload file "${sentFiles[index].name}"`)
                }
              })
              this.$emit('setVideoUrls', this.videos.map((v) => v.sourceUrl))
            })
            .finally(() => {
              this.$emit('onFileUpload', false)
            })
        })
    },
    removeVideo(index) {
      this.videos.splice(index, 1)
      this.$emit('setVideoUrls', this.videos.map((v) => v.sourceUrl))
    },
    onDragstart(evt, onDragItem) {
      if (this.isDisabled) return

      evt.currentTarget.classList.add('dragstart')
      this.onDragItem = onDragItem
    },
    onDrop(evt, onDropedItem, shift) {
      if (this.isDisabled || this.onDragItem === null) return

      evt.currentTarget.parentElement.classList.remove('dragover')
      this.$refs['mv-video-box'].forEach((item) => item.classList.remove('dragstart'))

      if (this.onDragItem !== onDropedItem) {
        this.videos.splice(this.videos.findIndex((vid) => vid === this.onDragItem), 1)
        if (onDropedItem === null) {
          this.videos.push(this.onDragItem)
        } else {
          this.videos.splice(this.videos.findIndex((vid) => vid === onDropedItem) + shift, 0, this.onDragItem)
        }
      }

      this.onDragItem = null
      this.$emit('setVideoUrls', this.videos.map((v) => v.sourceUrl))
    },
    onDragover(evt, video) {
      if (this.isDisabled || this.onDragItem === null) return

      this.$refs['mv-video-box'].forEach((item) => item.classList.remove('dragover'))
      if (this.onDragItem !== video) {
        evt.currentTarget.classList.add('dragover')
      }
    },
    showPosition(evt) {
      if (this.isDisabled || this.onDragItem === null) return

      this.$refs['drop-catch-field'].forEach((item) => item.classList.remove('dragging'))
      evt.currentTarget.classList.add('dragging')
    },
    cancelDrop() {
      if (this.isDisabled) return

      this.$refs['mv-video-box']?.forEach((item) => {
        item.classList.remove('dragstart')
        item.classList.remove('dragover')
      })
      this.$refs['drop-catch-field']?.forEach((item) => item.classList.remove('dragging'))

      this.onDragItem = null
    },
    openVideo(videoUrl) {
      this.openedVideoUrl = videoUrl
      this.$refs['video-modal'].show()
    },
    closeVideo() {
      this.$refs['video-modal'].hide()
    },
    videoStatus(state) {
      const statuses = {
        skipped: {
          label: 'Skipped',
          iconClass: 'far fa-times'
        },
        live: {
          label: 'Published',
          iconClass: 'far fa-check'
        },
        failed: {
          label: 'Failed',
          iconClass: 'far fa-times'
        },
        waiting_processing: {
          label: 'Pending',
          iconClass: 'far fa-clock'
        },
        processing: {
          label: 'Processing',
          iconClass: 'far fa-spinner-third fa-spin'
        },
        scheduled_in_future: {
          label: 'Scheduled in future',
          iconClass: 'far fa-clock'
        }
      }

      return statuses[state]
    }
  }
}
</script>
