import Index from '../vue_widgets/geogrid_api_keys'

Styxie.Initializers.GeogridApiKeys = {
  index({ baseDomain }) {
    $(() => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-geogrid-api-keys'),
        render: (h) => h(Index, { props: { baseDomain } })
      })
    })
  }
}
