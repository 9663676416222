import { App } from 'common/rails'

export function subscribe(channelName, callback) {
  const eventCallbacks = typeof callback === 'function' ? { received: callback } : callback
  return App.cable.subscriptions.create(channelName, eventCallbacks)
}

export function removeChannel(channel) {
  App.cable.subscriptions.remove(channel)
}

export const periodicityOptions = [
  { name: 'Weekly', value: 'weekly' },
  { name: 'Monthly', value: 'monthly' },
  { name: 'Day of the Month', value: 'day_of_month' },
  { name: 'Manual Run', value: 'once' }
]

export const daysOptions = [
  { name: 'Sunday', value: 0 },
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 }
]

export const weekOptions = [
  { name: 'First', value: 1 },
  { name: 'Second', value: 2 },
  { name: 'Third', value: 3 },
  { name: 'Fourth', value: 4 }
]

export const LIMIT_COMPETITORS = 5

export const AUDIT_REPORT_CONFIG_ID_KEY = 'audit_report_config_id'
