<template>
  <div>
    <h2 class="mb-2 font-weight-semi-bold">Page Speed Insights</h2>
    <AuditDescription class="mb-4">
      PageSpeed Insights is one of Google's Core Web Vitals metrics. It is a measure of how quickly your website loads for site visitors.
      <a href="https://developers.google.com/speed/docs/insights/v5/about" target="_blank">Documentation</a>
    </AuditDescription>
    <VueSlimTable
      ref="table"
      :source="() => pageSpeed"
      :columns="columns"
      class="responsive-table borderless-table audit-table page-speed">
      <template #head:fcp>
        <span>FCP</span>
        <i id="audit_report_page_speed_fcp" class="noprint far fa-info-circle color-primary" />
        <b-tooltip target="audit_report_page_speed_fcp" title="FCP (First Contentful Paint), milliseconds.
This metric measures the time from the start of page loading to the moment when any part of the page content is displayed on the screen." />
      </template>
      <template #head:lcp>
        <span>LCP</span>
        <i id="audit_report_page_speed_lcp" class="noprint far fa-info-circle color-primary" />
        <b-tooltip target="audit_report_page_speed_lcp" title="LCP (Largest Contentful Paint), milliseconds.
This metric reports the rendering time of the largest image or text block visible within the viewport, measured from the start of page loading." />
      </template>

      <template #row="{ row }">
        <tr class="page-speed__row">
          <td column-caption="Business name">
            <div class="page-speed__place">
              <p>{{ row.name }}</p>
              <span>
                <a
                  v-if="row.websiteHostname"
                  class="font-size-14 text-truncate"
                  :href="row.website"
                  target="_blank">{{ row.websiteHostname }}</a>
                <span v-else>
                  No website
                </span>
              </span>
            </div>
          </td>
          <td column-caption="PSI">
            <div class="page-speed__line" :class="{ disabled: row.desktop.psi === NA }">
              <div class="page-speed__platform">
                <div class="page-speed__icon"><i class="far fa-desktop" /></div>
                <div class="page-speed__percentage">{{ row.desktop.psi }}</div>
              </div>
              <div class="page-speed__bar"
                :class="row.desktop.category !== NA ? `page-speed__bar--${barColors[row.desktop.category]}` : ''">
                <div :style="{ width: row.desktop.psi }" />
              </div>
            </div>
            <div class="page-speed__line" :class="{ disabled: row.mobile.psi === NA }">
              <div class="page-speed__platform">
                <div class="page-speed__icon"><i class="far fa-mobile" /></div>
                <div class="page-speed__percentage">{{ row.mobile.psi }}</div>
              </div>
              <div class="page-speed__bar" :class="`page-speed__bar--${barColors[row.mobile.category]}`">
                <div :style="{ width: row.mobile.psi }" />
              </div>
            </div>
          </td>
          <td column-caption="FCP">
            <div class="page-speed__line" :class="{ disabled: row.desktop.fcp === NA }">{{ row.desktop.fcp }}</div>
            <div class="page-speed__line" :class="{ disabled: row.mobile.fcp === NA }">{{ row.mobile.fcp }}</div>
          </td>
          <td column-caption="LCP">
            <div class="page-speed__line" :class="{ disabled: row.desktop.lcp === NA }">{{ row.desktop.lcp }}</div>
            <div class="page-speed__line" :class="{ disabled: row.mobile.lcp === NA }">{{ row.mobile.lcp }}</div>
          </td>
        </tr>
      </template>
    </VueSlimTable>
  </div>
</template>

<script>

export default {
  props: {
    sectionData: { type: Object, required: true }
  },
  created() {
    this.columns = [
      { key: 'name', title: 'Competitors' },
      { key: 'psi', title: 'PSI' },
      { key: 'fcp', title: 'FCP' },
      { key: 'lcp', title: 'LCP' }
    ]
    this.barColors = {
      FAST: 'green',
      AVERAGE: 'yellow',
      SLOW: 'red'
    }
    this.NA = 'N/A'

    const { sectionData } = this
    const valueOrNa = (val) => val ?? this.NA

    const fullNA = {
      psi: this.NA,
      category: this.NA,
      fcp: this.NA,
      lcp: this.NA
    }

    this.pageSpeed = [
      { ...sectionData.current, name: 'My Location' },
      ...sectionData.competitors
    ].map(({
      name,
      website,
      desktop,
      mobile
    }) => ({
      name,
      website,
      websiteHostname: website ? new URL(website).hostname : '',
      desktop: desktop ? {
        psi: desktop.psi ? `${Math.round(desktop.psi * 100)}%` : this.NA,
        category: valueOrNa(desktop.category),
        fcp: valueOrNa(desktop.fcp),
        lcp: valueOrNa(desktop.lcp)
      } : fullNA,
      mobile: mobile ? {
        psi: mobile.psi ? `${Math.round(mobile.psi * 100)}%` : this.NA,
        category: valueOrNa(mobile.category),
        fcp: valueOrNa(mobile.fcp),
        lcp: valueOrNa(mobile.lcp)
      } : fullNA
    }))
  }
}
</script>
