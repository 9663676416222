<template>
  <tr :class="{ 'mv-employee-blocked': row.blockedAt }">
    <td class="text-break w-20">
      <a v-if="!organizationLinda" :href="employeeEditUrl">{{ row.name }} {{ row.lastName }}</a>
      <span v-else>{{ row.name }} {{ row.lastName }}</span>
    </td>
    <td class="text-break w-20">
      {{ row.email }}
    </td>
    <td class="text-primary w-8">
      <component
        :is="row.blockedAt ? 'span' : 'a'"
        :href="`${allMomentsPath}?status=pending&employee=${row.id}`"
        target="_blank">
        {{ row.pendingCount }}
      </component>
    </td>
    <td class="text-primary w-8">
      <component
        :is="row.blockedAt ? 'span' : 'a'"
        :href="`${allMomentsPath}?status=approved&employee=${row.id}`"
        target="_blank">
        {{ row.approvedCount }}
      </component>
    </td>
    <td class="text-primary w-8">
      <component
        :is="row.blockedAt ? 'span' : 'a'"
        :href="`${allMomentsPath}?status=rejected&employee=${row.id}`"
        target="_blank">
        {{ row.rejectedCount }}
      </component>
    </td>
    <td class="text-primary w-8">
      <component
        :is="row.blockedAt ? 'span' : 'a'"
        :href="`${allMomentsPath}?employee=${row.id}`"
        target="_blank">
        {{ row.totalCount }}
      </component>
    </td>
    <td class="text-break">
      <template v-if="!row.blockedAt">
        <span v-if="row.allBusinessesAccess" class="font-weight-semi-bold">All</span>
        <div v-else class="d-flex align-items-center gap-1">
          {{ row.businesses.length }}
          <span class="text-primary"
            data-toggle="tooltip"
            data-html="true"
            :data-original-title="businessesTooltipData">
            <i class="far fa-info-circle" />
          </span>
        </div>
      </template>
      <span v-else>
        Blocked
      </span>
    </td>
    <td class="text-break">
      {{ row.description }}
    </td>
    <td class="text-center w-11">
      <label v-if="!row.blockedAt" class="switcher d-inline-flex">
        <input type="checkbox" v-model="moderationVal" @input="toggleModeration">
        <i class="toogle-switch" />
      </label>
      <span v-else>
        Blocked
      </span>
    </td>
  </tr>
</template>

<script>
import snakecaseKeys from 'snakecase-keys'
import { DEFAULT_ERROR_MESSAGE } from '../../../common/constants'

const ALL_MOMENTS_PATH = '/gb/moments'

export default {
  props: {
    row: { type: Object, required: true },
    organizationLinda: { type: Boolean, required: true, default: false }
  },
  data() {
    return {
      moderationVal: this.row.moderationEnabled
    }
  },
  created() {
    this.allMomentsPath = ALL_MOMENTS_PATH
  },
  computed: {
    employeeEditUrl() {
      return `${window.location.pathname}/${this.row.id}/edit`
    },
    businessesTooltipData() {
      const businessesList = this.row.businesses.map(({ name }) => name)
      return `<div class='text-left'>${businessesList.join('<br>')}</div>`
    }
  },
  methods: {
    toggleModeration(val) {
      const prms = {
        mobileEmployee: {
          moderationEnabled: val.target.checked,
          businesses: this.row.businesses.map(({ id }) => id)
        }
      }

      return axios.patch(`/gb/ajax/employees/${this.row.id}`, snakecaseKeys(prms))
        .then(() => {
          toastr.success('Employee updated successfully')
        })
        .catch(() => {
          this.moderationVal = !val.target.checked
          toastr.error(DEFAULT_ERROR_MESSAGE)
        })
    }
  }
}
</script>
