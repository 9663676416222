<template>
  <div ref="wrapper">
    <button type="button" class="btn btn-sm btn-secondary" @click.prevent="changeSelectedImage(null)">Back</button>

    <div class="col-md-12 bg-gray-lighter mt-3 pt-3 pb-3">
      <multiselect
        class="exif-template-select"
        track-by="id"
        label="title"
        :disabled="exifState === 'clean'"
        :options="templates"
        :multiple="false"
        :searchable="true"
        :show-labels="true"
        :allow-empty="false"
        :select-label="false"
        :deselect-label="false"
        v-model="exifTemplate" />
      <a v-if='exifState != "clean"' href="#" class="dashed-link ml-4" @click.prevent="cleanCurrentExifs">Clear EXIF</a>
      <a v-if='exifState == "clean"' href="#" class="dashed-link ml-4" @click.prevent="setCurrentExifs">Set EXIF</a>

      <div class="row mt-4 advenced-exifs">
        <div class="col-md-7">
          <AdvancedMain />
          <AdvancedGps />
          <AdvancedDate />
        </div>
        <div class="col-md-5 pl-0">
          <AdvancedPreview />
          <AdvancedAdvanced />
        </div>

        <AdvancedNaming />
      </div>
    </div>

    <button type="button" class="btn btn-primary mt-3" @click.prevent="imageSelected">Ok</button>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Multiselect from 'vue-multiselect'

import AdvancedMain from './advanced/main'
import AdvancedGps from './advanced/gps'
import AdvancedDate from './advanced/date'
import AdvancedPreview from './advanced/preview'
import AdvancedAdvanced from './advanced/advanced'
import AdvancedNaming from './advanced/naming'

const CURRENT_OPTION = { title: 'Current EXIF', id: 'current' }
const EMPTY_OPTION = { title: '', id: null }

export default {
  data() {
    return {
      exifTemplate: CURRENT_OPTION,
      templates: [],
      fetchInProgress: false
    }
  },
  computed: mapState(['exifTemplates', 'selectedImageUploading', 'selectedImageFetchingExifs', 'exifState']),
  methods: {
    ...mapActions(['replaceCurrentExifs', 'imageSelected']),
    ...mapMutations(['changeImageStep', 'changeSelectedImage']),
    toggleWrapper(bool) {
      $(this.$refs.wrapper).toggleWrapper({}, bool)
    },
    cleanCurrentExifs() {
      this.exifTemplate = EMPTY_OPTION
      this.$store.dispatch('cleanCurrentExifs')
    },
    setCurrentExifs() {
      this.exifTemplate = CURRENT_OPTION
      this.replaceCurrentExifs('current')
    }
  },
  watch: {
    selectedImageUploading: 'toggleWrapper',
    selectedImageFetchingExifs: 'toggleWrapper',
    exifTemplate({ id }) {
      this.replaceCurrentExifs(id)
    }
  },
  mounted() {
    if (this.selectedImageFetchingExifs) this.toggleWrapper(true)
  },
  components: {
    AdvancedMain,
    AdvancedGps,
    AdvancedDate,
    AdvancedPreview,
    AdvancedAdvanced,
    AdvancedNaming,
    Multiselect
  },
  created() {
    this.templates = [
      this.exifTemplate,
      { title: 'Fill with Recommended EXIF data', id: 'lv-recommended' },
      { ...EMPTY_OPTION, $isDisabled: true },
      ...this.exifTemplates
    ]
  }
}
</script>
