<template>
  <div class="form-group">
    <div class="row">
      <label for="video_file" class="col-12 col-form-label col-sm-3 d-flex gap-2 justify-content-end align-items-center mt-n2">
        Video
        <span class="badge badge-primary rounded">beta</span>
      </label>
      <div class="col-12 col-sm-8 col-lg-6">
        <label v-if="!videoUrl" :class="['btn', { disabled: object.obfuscatedId }]">
          Select Video
          <input type="file"
            id="video_file"
            :accept="acceptTypes"
            @change="uploadVideo"
            class="d-none">
        </label>
        <div class="d-flex align-items-center gap-4">
          <button v-if="videoUrl"
            :disabled="state.type === 'text-primary'"
            type="button"
            class="btn"
            @click="resetControl">
            Remove video
          </button>
          <span v-if="state.text" :class="['d-block small mt-1', state.type]">
            <i v-if="state.type === 'text-primary'" class="far fa-spinner-third fa-spin mr-1" />
            {{ state.text }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="videoUrl" class="row">
      <div class="col-12 col-sm-4 offset-sm-3 col-lg-6">
        <video :src="videoUrl" class="mt-2 mw-100" controls />
        <div class="alert alert-warning m-0">
          Please note: Google might review videos up to 48 hours.
        </div>
      </div>
    </div>
    <div v-else-if="object.mediaFormat === 'video'" class="row">
      <div class="col-12 col-sm-4 offset-sm-3 col-lg-6">
        <img v-if="imageSrc" :src="imageSrc" class="img-thumbnail d-block my-2">
        <div class="alert alert-warning m-0">
          Preview is unavailable for videos uploaded to GBP with third party applications.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    acceptTypes: { type: String, default: '.mp4, .mkv, .webm, .mov' }
  },
  computed: {
    ...mapState(['object']),
    imageSrc() {
      if (this.object.galleryImage) return this.object.galleryImage.url
      return this.object.imagePath
    }
  },
  data() {
    return {
      state: {
        type: '',
        text: ''
      },
      videoUrl: null
    }
  },
  created() {
    this.videoUrl = this.object.videoUrl
    this.uploadUrl = this.object.remoteLocationId ?
      `/api/gmb/locations/${this.object.remoteLocationId}/media/video_upload_url` :
      '/api/gmb/multi_location_media/video_upload_url'
  },
  methods: {
    ...mapMutations(['updateVideoUrl', 'setLockedSubmit']),
    uploadVideo(e) {
      this.setLockedSubmit(true)
      this.state = { type: '', text: '' }
      const [file] = e.target.files

      axios.get(this.uploadUrl, {
        params: {
          filename: file.name
        }
      }).then((res) => {
        this.videoUrl = URL.createObjectURL(file)
        this.state = {
          type: 'text-primary',
          text: 'File is uploading'
        }
        axios.put(res.data.upload_url, file, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json'
          }
        }).then(() => {
          this.updateVideoUrl(res.data.upload_file_path)
          this.state = { type: '', text: '' }
        }).catch(() => {
          this.updateVideoUrl(null)
          this.videoUrl = null
          this.state = {
            type: 'text-danger',
            text: 'File upload error'
          }
        }).finally(() => {
          this.setLockedSubmit(false)
        })
      }).catch(() => {
        this.updateVideoUrl(null)
        this.videoUrl = null
        this.state = {
          type: 'text-danger',
          text: 'File upload error'
        }
        this.setLockedSubmit(false)
      })
    },
    resetControl() {
      this.videoUrl = null
      this.updateVideoUrl(null)
    }
  }
}
</script>
