var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-gallery-container scroll hor",on:{"scroll":_vm.infiniteScroll}},[_vm._l((_vm.images),function(image,index){return _c('div',{key:image.id,staticClass:"media-gallery-wrapper",on:{"click":function($event){return _vm.selectImage(image.id)}}},[_c('div',{class:[
        'media-gallery-image',
        {
          selected: _vm.isSelected(image.id),
          loading: image.status === 'uploading',
          success: image.status === 'success',
          error: image.status === 'error',
        },
      ]},[(image.progress)?_c('AppProgress',{attrs:{"data-dasharray":[Math.ceil(image.progress), 100],"radius":4}}):(image.dataURL || image.previewUrl)?_c('img',{attrs:{"src":image.dataURL || image.previewUrl,"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.showImage(index)}}}):[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" "),(image.error)?_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(image.error.msg))]):_vm._e()],_vm._v(" "),(image.url)?_c('button',{ref:"copyUrlButton",refInFor:true,staticClass:"btn btn-pretender media-gallery-image-copy",attrs:{"id":`copy-url-button-${index}`},on:{"click":function($event){$event.stopPropagation();return _vm.copyImageUrl(index)}}},[_c('i',{staticClass:"far fa-copy"})]):_vm._e(),_vm._v(" "),_c('b-tooltip',{attrs:{"target":`copy-url-button-${index}`,"triggers":"hover","noninteractive":""}},[_vm._v("Copy image url")])],2),_vm._v(" "),_c('div',{staticClass:"media-gallery-image-controls"},[_c('i',{class:['checked', { selected: _vm.isSelected(image.id), 'error-upload': image.error }]}),_vm._v(" "),_c('div',{class:[
        'd-flex align-items-center justify-content-between gap-1 font-size-14',
        { 'text-primary': _vm.isSelected(image.id), 'color-dark': Boolean(image.tags?.length) }
      ]},[_c('i',{staticClass:"far fa-tag"}),_vm._v("\n        "+_vm._s(image.tags?.length ?? 0)+"\n      ")])])])}),_vm._v(" "),(_vm.showMoreLoading)?_c('div',{staticClass:"loader"},[_c('i',{staticClass:"far fa-spinner-third fa-spin"})]):_vm._e(),_vm._v(" "),(_vm.imageIndex !== null)?_c('PreviewImages',{attrs:{"media":_vm.images,"current-media-index":_vm.imageIndex},on:{"close":_vm.closeImage}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }