<template>
  <div>
    <h2 class="mb-0 pb-2 font-weight-semi-bold">Media posts</h2>
    <AuditDescription class="mb-4">
      The number of pictures and videos added to a Google Business Profile. This media is visible to anyone who finds the business on Google Maps.
    </AuditDescription>
    <p class="mb-4 pb-2 text-muted">Some historical media data might be missing due to Google limitations.</p>

    <h3 class="h4 mb-2 font-weight-semi-bold">Media posts for all time</h3>
    <div class="widget p-0 mb-4">
      <div ref="audit-line-chart" />
    </div>

    <h3 class="h4 mb-2 font-weight-semi-bold">Media posts: Past 180 Days</h3>
    <VueSlimTable
      ref="table"
      :source="() => mediaArr"
      :columns="columns"
      class="responsive-table borderless-table audit-table media-posts average last-head-col-right-1">
      <template #row="{ row, columns }">
        <tr>
          <td class="text-lg-left"
            :key="columns[0].key"
            :column-caption="columns[0].title"
            @click="showContentSidebar(row, 'media')">
            <span class="text-primary cursor-pointer">{{ row.name }}</span>
          </td>
          <td v-for="({ key, title }, index) in getDefaultColumns(columns)"
            :key="key"
            :column-caption="title"
            :class="index + 1 !== (columns.length - 1) ? 'text-lg-left' : 'text-right'">
            <span>{{ row[key] }}</span>
          </td>
        </tr>
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import lineChartMixin from './line_chart_mixin'
import showContentSidebarMixin from './show_content_sidebar_mixin'

export default {
  mixins: [lineChartMixin, showContentSidebarMixin],
  props: {
    sectionData: { type: Object, required: true }
  },
  created() {
    this.columns = [
      { key: 'name', title: 'Competitors' },
      { key: 'video', title: 'Videos' },
      { key: 'image', title: 'Images' },
      { key: 'streetView', title: 'Street Views' },
      { key: 'panorama', title: 'Panoramas' },
      { key: 'total', title: 'Total Count' }
    ]

    this.mediaArr = [
      this.sectionData.current,
      ...this.sectionData.competitors
    ].map((c) => {
      const total = c.video + c.image + c.streetView + c.panorama
      return {
        name: 'My Location',
        ...c,
        total
      }
    })
  },
  methods: {
    getDefaultColumns(columns) {
      // eslint-disable-next-line no-unused-vars
      const [_nameColumn, ...rest] = columns
      return rest
    }
  }
}
</script>
