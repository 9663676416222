<template>
  <div class="form-field form-field--rem form-field--grid">
    <span class="m-0">{{ label }}</span>
    <Multiselect
      :class="{'multiselect--min-height': contentMinHeight}"
      :value="val"
      :options="values"
      :allow-empty="allowEmpty"
      :close-on-select="!multiple"
      :show-labels="true"
      :preselect-first="preselectFirst"
      :open-direction="openDirection"
      :disabled="disabled"
      :multiple="multiple"
      :placeholder="placeholder"
      :label="labelSelect"
      :track-by="trackBy"
      @input="handleInput"
      @select="handleSelect">
      <template slot="selection" slot-scope="props">
        <span class="multiselect__single" v-if="props.values.length && !props.isOpen">
          {{ props.values.length }} business{{ props.values.length !== 1 ? 'es' : '' }} selected
        </span>
      </template>
      <template #option="{ option }">
        <div class="option__desc text-truncate">
          <span class="option__title">
            {{ option.text }}
          </span>
          <template v-if="option.description && option.description.length">
            <small v-for="(item, index) in option.description" :key="index" class="d-block text-muted text-normal text-break">{{ item }}</small>
          </template>
        </div>
      </template>
    </Multiselect>
    <div v-if="error" class="text-danger">{{ errorText }}</div>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    value: {
      type: [String, Number, Array, Object],
      required: true
    },
    values: {
      type: Array,
      required: true
    },
    native: {
      type: Boolean,
      required: false,
      default: true
    },
    label: {
      type: String,
      required: false
    },
    labelSelect: { type: String, default: 'text' },
    trackBy: { type: String, default: 'value' },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    errorText: {
      type: String,
      required: false
    },
    type: {
      required: false,
      default: 'text'
    },
    disabled: {
      required: false,
      default: false
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false
    },
    allowEmpty: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Select option'
    },
    preselectFirst: {
      type: Boolean,
      required: false,
      default: false
    },
    openDirection: {
      type: String,
      required: false
    },
    contentMinHeight: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    handleInput(newValue) {
      const valueToEmit = this.multiple ? newValue.map((selectedOption) => selectedOption.value) : newValue?.value

      this.$emit('input', valueToEmit ?? '')
    },
    handleSelect(e) {
      this.$emit('select', e)
    }
  },
  computed: {
    val() {
      if (this.multiple) {
        return this.values.filter((elm) => this.value.includes(elm.value))
      }

      const value = this.values.find((elm) => elm.value === this.value)

      if (!value && !this.allowEmpty) {
        return this.values[0]
      }

      return value
    }
  }
}
</script>
