<template>
  <div>
    <div class="d-flex align-items-center gap-2 text-dark mb-2">
      <span class="font-size-18 font-weight-semi-bold">Image</span>
    </div>
    <div :class="['mv-image-box-container', { disabled: isDisabled, dragstart: onDragItem && !isDisabled }]"
      @dragover.prevent
      @dragenter.prevent
      @drop="cancelDrop">
      <template v-if="images">
        <div v-for="(image, index) in images" :key="index"
          :class="['mv-image-box', { cover: index === 0 }]"
          ref="mv-image-box"
          draggable
          @dragstart="onDragstart($event, image)"
          @dragover.prevent.stop="onDragover($event, image)"
          @drop="onDrop($event, image, 0)">
          <img :src="image.url">
          <button v-if="!isDisabled" type="button" @click="removeImage(index)" />
          <div ref="drop-catch-field" class="drop-catch-field left" @dragover="showPosition($event)" @drop.prevent.stop="onDrop($event, image, 0)" />
          <div ref="drop-catch-field" class="drop-catch-field right" @dragover="showPosition($event)" @drop.prevent.stop="onDrop($event, image, 1)" />
        </div>
      </template>

      <div v-if="images.length < MAX_FILES" class="mv-image-btn">
        <input type="file"
          ref="file"
          multiple
          :accept="acceptTypes"
          :disabled="isDisabled"
          @change="onFileChange">
        <div class="mv-image-btn-label">
          <i class="far fa-plus-circle mb-1" />
          Add media
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const MAX_FILES = 10

export default {
  name: 'MomentMedia',
  props: {
    checkin: { type: Object, required: true },
    isRequestSent: { type: Boolean, default: false },
    acceptTypes: { type: String, default: 'image/png, image/jpg, image/jpeg' }
  },
  data() {
    return {
      images: this.checkin.images,
      onDragItem: null
    }
  },
  computed: {
    isDisabled() {
      return this.checkin.aasmState !== 'pending' || this.isRequestSent
    }
  },
  created() {
    this.MAX_FILES = MAX_FILES
  },
  methods: {
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) {
        return
      }

      const wrongFormatFiles = []
      const filteredFiles = Array.from(files).filter(({ name, type }) => {
        if (this.acceptTypes.includes(type)) return true

        wrongFormatFiles.push(name)
        return false
      }).slice(0, MAX_FILES - this.images.length)

      if (wrongFormatFiles.length > 0) {
        toastr.warning(`Can't upload files:<br>${wrongFormatFiles.join('<br>')}`, { timeOut: 2000 })
      }

      this.$refs.file.value = ''
      if (!/safari/i.test(navigator.userAgent)) {
        this.$refs.file.type = ''
        this.$refs.file.type = 'file'
      }

      this.createImage(filteredFiles)
    },
    createImage(files) {
      const vm = this
      for (let index = 0; index < files.length; index++) {
        const reader = new FileReader()
        reader.onload = (event) => {
          const imageUrl = event.target.result
          vm.images.push({ url: imageUrl })
          this.$emit('setImages', this.images)
        }
        reader.readAsDataURL(files[index])
      }
    },
    removeImage(index) {
      this.images.splice(index, 1)
      this.$emit('setImages', this.images)
    },
    onDragstart(evt, onDragItem) {
      if (this.isDisabled) return

      evt.currentTarget.classList.add('dragstart')
      this.onDragItem = onDragItem
    },
    onDrop(evt, onDropedItem, shift) {
      if (this.isDisabled || this.onDragItem === null) return

      evt.currentTarget.parentElement.classList.remove('dragover')
      this.$refs['mv-image-box'].forEach((item) => item.classList.remove('dragstart'))

      if (this.onDragItem !== onDropedItem) {
        this.images.splice(this.images.findIndex((img) => img === this.onDragItem), 1)
        if (onDropedItem === null) {
          this.images.push(this.onDragItem)
        } else {
          this.images.splice(this.images.findIndex((img) => img === onDropedItem) + shift, 0, this.onDragItem)
        }
      }

      this.onDragItem = null
      this.$emit('setImages', this.images)
    },
    onDragover(evt, image) {
      if (this.isDisabled || this.onDragItem === null) return

      this.$refs['mv-image-box'].forEach((item) => item.classList.remove('dragover'))
      if (this.onDragItem !== image) {
        evt.currentTarget.classList.add('dragover')
      }
    },
    showPosition(evt) {
      if (this.isDisabled || this.onDragItem === null) return

      this.$refs['drop-catch-field'].forEach((item) => item.classList.remove('dragging'))
      evt.currentTarget.classList.add('dragging')
    },
    cancelDrop() {
      if (this.isDisabled) return

      this.$refs['mv-video-box']?.forEach((item) => {
        item.classList.remove('dragstart')
        item.classList.remove('dragover')
      })
      this.$refs['drop-catch-field']?.forEach((item) => item.classList.remove('dragging'))

      this.onDragItem = null
    }
  }
}
</script>
