<template>
  <div>
    <h2 class="h4 mb-2 pb-2 font-weight-semi-bold">Zip Codes</h2>
    <AuditDescription class="mb-4">
      The ZIP codes where your competitors are physically located.
    </AuditDescription>

    <div class="widget mb-2">
      <div ref="zip-codes-chart" class="pie-chart" />
    </div>

    <div class="d-flex flex-column flex-hd-row justify-content-between gap-2 mt-auto mb-3">
      <div class="d-flex gap-2 w-100">
        <VueSlimTable
          :source="() => places"
          :columns="columns"
          class="responsive-table borderless-table audit-table zip-codes-table last-head-col-right-1">
          <template #row="{ row, columns }">
            <tr>
              <td v-for="({ key, title }, index) in columns"
                :key="key"
                :column-caption="title"
                :class="[{'text-grey': !row[key] }, index !== columns.length - 1 ? 'text-lg-left' : 'text-right' ]">
                {{ row[key] || 'N/A' }}
              </td>
            </tr>
          </template>
        </VueSlimTable>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'
import chartPaletteMixin from './chart_palette_mixin'

export default {
  mixins: [chartPaletteMixin],
  props: {
    sectionData: { type: Object, required: true }
  },
  created() {
    this.columns = [
      { key: 'name', title: 'Business Name' },
      { key: 'address', title: 'Address' },
      { key: 'zip', title: 'Zip Code' }
    ]

    this.chartData = Object.entries(this.sectionData.places.reduce((mem, { zip }) => {
      const defZip = zip || 'N/A'

      if (!mem[defZip]) mem[defZip] = 0
      mem[defZip] += 1
      return mem
    }, {})).map(([name, y]) => ({ name, y }))

    this.places = this.sectionData.places.map((row, index) => (index ? row : { ...row, name: 'My Location' }))
  },
  mounted() {
    this.chart = Highcharts.chart(
      this.$refs['zip-codes-chart'],
      {
        chart: {
          type: 'pie',
          spacing: [0, 0, 0, 0],
          backgroundColor: 0,
          height: '271px'
        },
        credits: { enabled: false },
        title: { text: '' },
        plotOptions: {
          pie: {
            showInLegend: true,
            startAngle: -90,
            endAngle: 90,
            center: ['50%', '100%'],
            size: '180%'
          }
        },
        legend: {
          align: 'right',
          verticalAlign: 'middle',
          itemMarginTop: 8,
          itemMarginBottom: 8,
          x: 0,
          width: '50%'
        },
        tooltip: {
          enabled: true,
          borderWidth: 0,
          borderRadius: 0,
          padding: 0,
          formatter() {
            return `<strong>${this.key} (${this.y})</strong>`
          },
          useHTML: true,
          shadow: false,
          className: 'pie-chart-tooltip'
        },
        series: [{
          borderWidth: 3,
          dataLabels: {
            enabled: true,
            formatter() {
              return `<span style="color: ${this.point.color}">${Math.round(this.point.percentage)}%</span>`
            },
            className: 'data-label-text data-label-text--zip-codes',
            defer: true,
            distance: 0,
            y: -5,
            connectorWidth: 0,
            connectorPadding: 0,
            padding: 1
          },
          data: this.chartData,
          ignoreHiddenPoint: false,
          innerSize: '65%',
          states: {
            hover: { enabled: false },
            inactive: { enabled: false }
          }
        }],
        responsive: {
          rules: [{
            condition: { maxWidth: 992 },
            chartOptions: {
              legend: {
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal'
              }
            }
          }]
        },
        colors: this.pieChartColors
      }
    )
  }
}
</script>
