<template>
  <div class="report-posts">
    <h2 class="mb-3 pb-2 font-weight-semi-bold">Posts</h2>
    <AuditDescription class="mb-4">
      Google Business Profile posts are meant to provide information about the general services
      a business provides or they can give viewers a peek into specific, everyday actions.
    </AuditDescription>

    <h3 class="h4 mb-2 font-weight-semi-bold">Posts for all time</h3>
    <div class="widget p-0 mb-4">
      <div ref="audit-line-chart" />
    </div>

    <h3 class="h4 mb-2 font-weight-semi-bold">Posts: Past 180 days</h3>
    <VueSlimTable
      ref="table"
      :source="() => postsArr"
      :columns="columns"
      class="responsive-table borderless-table audit-table posts average last-head-col-right-1">
      <template #row="{ row }">
        <tr>
          <td column-caption="Competitors"
            @click="showContentSidebar(row, 'posts')">
            <span class="text-primary cursor-pointer">{{ row.name }}</span>
          </td>
          <td column-caption="Total Count">{{ row.total }}</td>
          <td class="text-right" column-caption="Posts Per Day">{{ row.avgFrequency.toFixed(2) }}</td>
        </tr>
      </template>
    </VueSlimTable>

    <h3 class="h4 mb-2 font-weight-semi-bold">Posts by type</h3>
    <div class="audit-table-wrapper">
      <div class="competitors-chart-legend">
        <div v-for="legendItem in postTypesObj" :key="legendItem.color" class="competitors-chart-legend-item">
          <div class="point" :style="{backgroundColor: legendItem.color}" />
          {{ legendItem.title }}
        </div>
      </div>
      <VueSlimTable
        ref="table"
        :source="() => postsArr"
        :columns="columns"
        class="responsive-table borderless-table audit-table posts-by-type average last-head-col-right-1 mt-3">
        <template #row="{ row }">
          <tr>
            <td @click="showContentSidebar(row, 'posts')">
              <span class="text-primary cursor-pointer">{{ row.name }}</span>
            </td>
            <td>
              <RatioBar :parts="row.byType" :total="maxTotal" />
            </td>
            <td class="font-weight-bold">{{ row.total }}</td>
          </tr>
        </template>
      </VueSlimTable>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts'

import lineChartMixin from './line_chart_mixin'
import showContentSidebarMixin from './show_content_sidebar_mixin'

import RatioBar from './blanks/ratio_bar'

Highcharts.seriesTypes.line.prototype.drawLegendSymbol = Highcharts.seriesTypes.column.prototype.drawLegendSymbol

export default {
  components: {
    RatioBar
  },
  mixins: [lineChartMixin, showContentSidebarMixin],
  props: {
    sectionData: { type: Object, required: true }
  },
  created() {
    this.postTypesObj = {
      standard: { color: '#377DFF', title: 'Standard post' },
      event: { color: '#29BA6C', title: 'Event' },
      offer: { color: '#94DDB6', title: 'Offer' }
    }

    this.columns = [
      { key: 'name', title: 'Location', orderable: false },
      { key: 'total', title: 'Total Count', orderable: false },
      { key: 'avgFrequency', title: 'Posts Per Day', orderable: false }
    ]

    this.maxCount = 1
    this.postsArr = [
      this.sectionData.current,
      ...this.sectionData.competitors
    ].map((c) => {
      const total = Object.keys(this.postTypesObj).reduce((mem, key) => mem + c[key], 0)

      if (this.maxCount < total) this.maxCount = total

      return {
        name: 'My Location',
        ...c,
        total,
        byType: Object.keys(this.postTypesObj).reduce((mem, key) => {
          mem.push({
            color: this.postTypesObj[key].color,
            value: c[key]
          })
          return mem
        }, [])
      }
    })
    this.maxTotal = Math.max(...this.postsArr.map((business) => business.total))
  },
  mounted() {
    this.$refs['posts-chart-by-types']?.forEach((container, index) => {
      Highcharts.chart(
        container,
        {
          ...{
            chart: {
              type: 'pie',
              spacing: [0, 0, 0, 0],
              backgroundColor: 0
            },
            credits: { enabled: false },
            title: { text: '' },
            tooltip: {
              enabled: true,
              borderWidth: 0,
              borderRadius: 0,
              padding: 0,
              formatter() {
                return `<strong>${this.key} (${this.y})</strong>`
              },
              useHTML: true,
              shadow: false,
              className: 'pie-chart-tooltip'
            },
            legend: {
              align: 'right',
              verticalAlign: 'middle',
              itemMarginTop: 8,
              itemMarginBottom: 8,
              x: 0
            },
            series: [{
              data: Object.keys(this.postTypesObj).map((postType) => ({
                color: this.postTypesObj[postType].color,
                name: this.postTypesObj[postType].title,
                y: this.postsArr[index][postType]
              })).filter((post) => post.y > 0),
              dataLabels: {
                enabled: true,
                formatter() {
                  return `<span>${Math.round(this.point.percentage)}%</span>`
                },
                className: 'data-label-text',
                crop: false,
                alignTo: 'plotEdges'
              },
              size: '80%',
              borderWidth: 3,
              ignoreHiddenPoint: false,
              innerSize: '65%',
              states: {
                hover: { enabled: false },
                inactive: { enabled: false }
              }
            }]
          }
        }
      )
    })
  }
}
</script>
