Styxie.Initializers.Global = {
  index: (data) => {
    const { beginningOfWeek, ...wlc } = data
    wlc.gbName = data.appCodename === 'linda' ? 'GrowthBooster' : 'GeoBooster'

    Styxie.WLC = wlc
    Styxie.USER = {
      beginningOfWeek
    }
  }
}
