<template>
  <div class="mv-radio-panels" :class="{ error }">
    <span class="mv-radio-panels__label" v-if="label">{{ label }}</span>
    <div class="mv-radio-panels__list">
      <div
        class="mv-radio-panels__item"
        :class="{'mv-radio-panels__item--active': value === option.value}"
        v-for="option in values">
        <label>
          <input
            class="mv-radio-panels__value"
            type="radio"
            :name="name"
            :checked="value === option.value"
            :value="option.value"
            @input="$emit('input', option.value)">
          <i v-if="option.icon" class="mv-radio-panels__icon" :class="option.icon" />
          <span>{{ option.text }}</span>
        </label>
      </div>
    </div>
    <div v-if="error" class="mv-input__error text-danger">{{ errorText }}</div>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    values: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    errorText: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    handleRadioChange(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
