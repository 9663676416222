<template>
  <div class="container">
    <div class="d-flex flex-wrap justify-content-between align-items-center gap-2 mb-3">
      <div class="description">
        Create a token to communicate with our API. Check out
        <a class="external-link" target="_blank" :href='`https://developers.${baseDomain}`'>
          {{ brandname }} API Documentation <i class="far fa-external-link" />
        </a>
      </div>
      <div class='tools'>
        <a class="btn btn-primary gap-3 rounded" @click.prevent="generateToken">
          <i class="far fa-circle-plus fa-fw" />
          Generate New Token
        </a>
      </div>
    </div>

    <VueSlimTable
      ref="table"
      :columns="columns"
      class="table table-striped geogrid-api-keys-table"
      :source="tableSource">
      <template #cell:toggle="{ row }">
        <label class="switcher">
          <input type="checkbox" :checked="!row.disabledAt" @input="toogleToken({ e: $event, row: row })">
          <i class="toogle-switch" />
        </label>
      </template>
      <template #cell:token="{ row }">
        {{ row.token }}
        <button class="button-copy" @click.prevent="copyToClipboardWithAnimation({ element: $event.currentTarget, textToCopy: row.token })">
          <i class="far fa-fw fa-copy" />
        </button>
      </template>
    </VueSlimTable>
  </div>
</template>

<script>
import { copyToClipboardWithAnimation } from 'common/copy_to_clipboard'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'

export default {
  props: {
    baseDomain: { type: String, required: true }
  },
  methods: {
    generateToken() {
      axios.post('/api/geogrid_api_keys')
        .then(() => {
          this.$refs.table.refetch()
          toastr.success('New token generated')
        })
    },
    toogleToken({ e, row }) {
      axios.post(`/api/geogrid_api_keys/${row.token}/${e.target.checked ? 'en' : 'dis'}able`)
        .then((res) => {
          row = res.data
        })
        .catch(() => {
          toastr.error(DEFAULT_ERROR_MESSAGE)
          e.target.checked = !e.target.checked
        })
    },
    tableSource(params) {
      return axios.get('/api/geogrid_api_keys', { params })
        .then((res) => res.data)
    },
    copyToClipboardWithAnimation
  },
  created() {
    this.brandname = Styxie.WLC.brand
    this.columns = [
      { key: 'token', title: 'Token' },
      { key: 'toggle', title: 'Activate' }
    ]
  }
}
</script>
