<template>
  <div>
    <div class='form-field'>
      <span class='caption'>Schedule:</span>
      <b-form-radio-group
        class='mt-1 custom-control-inline'
        @change='onChangePeriodicity'
        v-model='periodicity'
        :options='periodicityOptions' />
    </div>
    <div v-if="periodicity === 'day_of_month'">
      <div class='form-field'>
        <span class='caption'>{{ periodicityOptions['day_of_month'] }}</span>
        <multiselect
          :options='dayNumberOptions'
          :allow-empty='false'
          :searchable='false'
          :multiple='false'
          :close-on-select='true'
          :clear-on-select='false'
          :preserve-search='true'
          :show-labels='false'
          v-model='schedule.monthDay' />
      </div>
    </div>
    <div v-else>
      <div class='d-sm-inline-flex w-100'>
        <div class='form-field pr-sm-3' style='flex: 1'>
          <span class='caption'>Day of week:</span>
          <multiselect
            label='text'
            :options='weekDayNumberOptions'
            :allow-empty='false'
            :searchable='false'
            :multiple='false'
            :close-on-select='true'
            :clear-on-select='false'
            :preserve-search='true'
            :show-labels='false'
            v-model='schedule.dayNumber' />
        </div>
        <div class='form-field' style='flex: 1'>
          <span class='caption'>Week number:</span>
          <multiselect
            :disabled="periodicity === 'weekly'"
            label='text'
            :options='weekNumberOptions'
            :allow-empty='false'
            :searchable='false'
            :multiple='false'
            :close-on-select='true'
            :clear-on-select='false'
            :preserve-search='true'
            :show-labels='false'
            placeholder='-every week-'
            v-model='schedule.weekNumber' />
        </div>
      </div>
    </div>

    <div class='d-sm-inline-flex w-100'>
      <div class='form-field' style='flex: 1'>
        <span class='caption'>
          Launch Hour:
          <i :id="select2prefix + 'hour-tooltip-trigger'" class="color-info far fa-question-circle" />
          <BTooltip :target="select2prefix + 'hour-tooltip-trigger'" triggers="hover">
            You can specify the time of day and timezone so that geogrids start at this time.
          </BTooltip>
        </span>
        <multiselect
          label='text'
          placeholder='–'
          :options='hoursOptions'
          track-by="id"
          :allow-empty='true'
          :searchable='false'
          :multiple='false'
          :close-on-select='true'
          :clear-on-select='false'
          :show-labels='false'
          v-model='schedule.hour' />
      </div>
    </div>
    <div class='d-sm-inline-flex w-100'>
      <div class='form-field' style='flex: 1'>
        <span class='caption'>Timezone:</span>
        <multiselect
          label='text'
          track-by="id"
          :options='timeZones'
          :allow-empty='false'
          :searchable='true'
          :multiple='false'
          :close-on-select='true'
          :clear-on-select='false'
          :show-labels='false'
          v-model='selectedTimeZone' />
      </div>
    </div>
    <div v-if='schedule.nextGeneration' class='form-field mt-1'>
      <span class='caption'>Next generation:</span>
      <span :class="{'text-danger': changed }">{{ schedule.nextGeneration }}</span>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import { BTooltip } from 'bootstrap-vue'

export default {
  name: 'ConfigSchedule',
  components: { Multiselect, BTooltip },
  props: {
    periodicityOptions: Object,
    weekDayNumberOptions: Array,
    weekNumberOptions: Array,
    select2prefix: String,

    // We have some kind of inconsistency which I don't like but I don't really understand
    // how to fix it in a better way:
    // – We use props only when we render new config form. The reason is that we have to
    //   dynamically change timeZone when user changes location (and in this case
    //   renderForm(schedule, nextGeneration) is not called by design).
    // – And on the edit form we use renderForm(schedule, nextGeneration) to update schedule
    //   when load config from database and don't use props.
    timeZone: String,
    timeZones: Array,
    isNew: Boolean
  },
  data() {
    return {
      schedule: {
        dayNumber: this.weekDayNumberOptions[0],
        monthDay: null,
        weekNumber: 1,
        nextGeneration: null,
        hour: null,
        timeZone: null
      },
      periodicity: 'weekly',
      changed: false,
      weekPlaceholder: 'Choose week number',
      week: null,
      dayNumberOptions: Array.from({ length: 31 }, (_, index) => index + 1),
      selectedTimeZone: {}
    }
  },
  created() {
    this.hoursOptions = [
      { id: 0, text: '0am' },
      { id: 1, text: '1am' },
      { id: 2, text: '2am' },
      { id: 3, text: '3am' },
      { id: 4, text: '4am' },
      { id: 5, text: '5am' },
      { id: 6, text: '6am' },
      { id: 7, text: '7am' },
      { id: 8, text: '8am' },
      { id: 9, text: '9am' },
      { id: 10, text: '10am' },
      { id: 11, text: '11am' },
      { id: 12, text: '12pm' },
      { id: 13, text: '1pm' },
      { id: 14, text: '2pm' },
      { id: 15, text: '3pm' },
      { id: 16, text: '4pm' },
      { id: 17, text: '5pm' },
      { id: 18, text: '6pm' },
      { id: 19, text: '7pm' },
      { id: 20, text: '8pm' },
      { id: 21, text: '9pm' },
      { id: 22, text: '10pm' },
      { id: 23, text: '11pm' }
    ]
    this.initialUserTimeZone = this.timeZone

    this.setInitialTimeZone()
  },
  methods: {
    renderForm(schedule, nextGeneration) {
      this.schedule.nextGeneration = nextGeneration
      this.schedule.dayNumber = this.weekDayNumberOptions[schedule.dayNumber]
      if (schedule.weekNumber) this.schedule.weekNumber = this.weekNumberOptions[schedule.weekNumber - 1]
      else this.schedule.weekNumber = null
      this.periodicity = schedule.periodicity
      this.schedule.monthDay = schedule.monthDay
      if (schedule.hour >= 0) {
        this.schedule.hour = this.hoursOptions.find((hour) => hour.id === schedule.hour)
      } else {
        this.schedule.hour = null
      }
      this.schedule.timeZone = schedule.timeZone
      this.setInitialTimeZone()

      this.$nextTick(() => {
        this.changed = false
      })
    },
    onChangePeriodicity(periodicity) {
      if (periodicity === 'weekly') {
        this.schedule.weekNumber = null
        this.schedule.monthDay = null
      } else if (periodicity === 'monthly') {
        [this.schedule.weekNumber] = this.weekNumberOptions
        this.schedule.monthNay = null
      } else {
        this.schedule.monthDay = 1
      }
    },
    setInitialTimeZone() {
      const currentTimeZone = this.schedule.timeZone || this.timeZone
      this.selectedTimeZone = this.timeZones.find((timeZone) => timeZone.id === currentTimeZone)
    }
  },
  computed: {
    ...mapState(['pageTab']),
    selectedLocationTimeZone() {
      return this.$store.state.chosenTimeZone
    },
    chosenTimeZone() {
      if (this.schedule.timeZone) {
        return this.schedule.timeZone
      }

      return this.timeZone
    },
    getSchedule() {
      return {
        periodicity: this.periodicity,
        monthDay: this.schedule.monthDay,
        dayNumber: this.schedule.dayNumber.id,
        weekNumber: this.schedule.weekNumber ? this.schedule.weekNumber.id : null,
        hour: this.schedule.hour ? this.schedule.hour.id : null,
        timeZone: this.chosenTimeZone
      }
    }
  },
  watch: {
    getSchedule: {
      deep: true,
      handler() {
        this.changed = true
      }
    },
    selectedTimeZone: {
      handler() {
        if (!this.selectedTimeZone) return
        this.schedule.timeZone = this.selectedTimeZone.id
      },
      deep: true
    },
    selectedLocationTimeZone(newVal) {
      const newTimeZone = newVal ?? this.timeZone

      const matchingTimeZone = this.timeZones.find((timeZone) => timeZone.id === newTimeZone)

      if (!matchingTimeZone) return

      this.selectedTimeZone = matchingTimeZone
    },
    pageTab() {
      if (this.pageTab === 'new' && this.isNew) {
        this.selectedTimeZone = this.timeZones.find((timeZone) => timeZone.id === this.initialUserTimeZone)
        return
      }
      this.setInitialTimeZone()
    }
  }
}
</script>
