<template>
  <div ref="propertyChart" />
</template>

<script>
import Highcharts from 'highcharts'
import moment from 'moment'
import { differenceClass } from 'common/helpers'

const tooltipPoint = (data, diff) => (
  '<div class="tooltip-line">' +
    `<span class="dash dash--solid" style="border-bottom-color:${data.color}"></span>` +
    `<span class="date">${moment(data.x).format('D MMM YYYY')}</span>` +
    `<span class="value">${data.y}</span>` +
    `<span class="difference ${differenceClass(diff)}">${Math.abs(diff)}</span></div>`
)

export default {
  props: {
    chartData: { type: Array, default: () => []}
  },
  mounted() {
    const tooltip = {
      backgroundColor: '#535c65',
      borderRadius: 4,
      borderWidth: 0,
      padding: 0,
      style: {
        fontSize: '14px',
        color: '#fff'
      },
      useHTML: true,
      shadow: false
    }
    tooltip.formatter = function formatter() {
      const i = this.series.data.indexOf(this.point)
      const memo = []
      this.series.chart.series.reduceRight((prev, series) => {
        if (series.data[i].y !== null) {
          const diff = prev.length !== 0 ? (series.data[i].y - prev.y) : 0
          memo.push(tooltipPoint(series.data[i], diff))
        }
        return series.data[i]
      }, [])

      return memo.reverse().join('')
    }

    if (this.chart) this.chart.destroy()

    this.chart = Highcharts.chart(this.$refs.propertyChart, {
      alignTicks: false,
      title: false,
      chart: {
        height: 75
      },
      credits: { enabled: false },
      legend: { enabled: false },
      xAxis: [
        { type: 'datetime', visible: false },
        { type: 'datetime', opposite: true, visible: false }
      ],
      yAxis: [{ visible: false }],
      plotOptions: {
        series: {
          clip: false
        }
      },
      time: { useUTC: true },
      series: this.chartData.map((data) => {
        data.marker = {
          enabled: false,
          state: {
            hover: {
              enabled: true
            }
          },
          symbol: 'circle'
        }
        return data
      }),
      tooltip
    })
  }
}
</script>
