<template>
  <tr :class="{ checked: row.checked }">
    <td class="cell-business">
      <div :class="['d-flex position-relative', { 'disabled': row.nextGeneration === 'not specified' }]">
        <Checkbox :value="row.checked" @input="onRowCheckToggle" />
        <div>
          <a v-if="!row.deletedAt" :href='row.url' @click.exact.prevent.stop="showConfig(row.obfuscatedId)" class="d-block ml-1 pr-5">
            {{ row.trackedBusiness }}
          </a>
          <span v-else class="ml-1">{{ row.trackedBusiness }}</span>
          <small class='d-block ml-1' v-show='row.address'>{{ row.address }}</small>
          <Tags
            :ref='`${row.obfuscatedId}-tags`'
            :initial-tags='row.tags'
            :searchable-tags='true'
            @addTagToFilter='addTagToFilter' />
          <CategoriesList v-if="row.businessCategories?.length" :categories="row.businessCategories" />
          <div v-if='row.labels' class='location-labels'>
            <span v-for='label in row.labels' :key='`${label}${row.obfuscatedId}`' class='location-labels-item'>
              {{ label }}
            </span>
          </div>
        </div>
        <img v-if="row.totalGeogrids.top3AppearedCount && row.totalGeogrids.newTop3AppearedCount > 0"
          :src="require('top3_appeared_count.svg')"
          class="top3-appeared-count"
          alt="">
        <img v-else-if="row.totalGeogrids.newTop3AppearedCount > 0"
          :src="require('top3_appeared_count_green.svg')"
          class="top3-appeared-count"
          alt="">
        <img v-else-if="row.totalGeogrids.top3AppearedCount"
          :src="require('top3_appeared_count_blue.svg')"
          class="top3-appeared-count"
          alt="">
      </div>
    </td>
    <td class="cell-grid">
      <span :class="[{ 'disabled': row.nextGeneration === 'not specified' }]">
        {{ row.grid }}
      </span>
    </td>
    <td class="cell-schedule">
      <span :class="[{ 'disabled': row.nextGeneration === 'not specified' }]">
        {{ row.scheduleFormatted }}
      </span>
    </td>
    <td class="cell-last-geogrid">
      <span :class="[{ 'disabled': row.nextGeneration === 'not specified' }]">
        {{ row.lastGenerated }}
      </span>
    </td>
    <td class="cell-next-creation">
      <span :class="[{ 'disabled': row.nextGeneration === 'not specified' }]">
        {{ row.nextGeneration }}
        <small v-if="!linda && row.nextGeneration !== 'not specified'" class="d-block">
          <i class='far fa-wallet pr-1' />
          {{ row.credits }} credits
        </small>
      </span>
    </td>
    <td class="cell-geogrids">
      <div :class="[{ 'disabled': row.nextGeneration === 'not specified' }]">
        <div v-if="!row.deletedAt">
          <i class='far fa-th pr-1' />
          {{ row.totalGeogrids.totalGeogrids }} geogrids
        </div>
        <small v-if="row.totalGeogrids.inactive === 0">{{ row.totalGeogrids.active }} keywords</small>
        <div v-else>
          <small class="d-block">{{ row.totalGeogrids.active }} enabled keywords</small>
          <small class="d-block">{{ row.totalGeogrids.inactive }} disabled keywords</small>
        </div>
      </div>
    </td>
    <td class="cell-actions">
      <div v-if="!row.deletedAt" class="d-flex flex-column gap-2">
        <a class="btn btn-link" :href="row.publicUrl" target="_blank" :id="`open-public-config-${row.obfuscatedId}`">
          <i class="far fa-globe" />
        </a>
        <b-popover
          placement='left'
          :target="`open-public-config-${row.obfuscatedId}`"
          custom-class='geogrids-popover p-1'
          triggers='hover'>
          Open public page
        </b-popover>
        <BtnCopyLinkToClipboard classes='btn btn-link' entity-name='config' placement='left' :link='row.url' />
        <button @click="showConfigsEditTab(row.obfuscatedId)" class='btn btn-link' :id="`edit-config-${row.obfuscatedId}`">
          <i class='far fa-cog' />
        </button>
        <b-popover
          placement='left'
          :target="`edit-config-${row.obfuscatedId}`"
          custom-class='geogrids-popover p-1'
          triggers='hover'>
          Edit config
        </b-popover>
        <button @click='deleteConfig(row)' class='btn btn-link' :id="`delete-config-${row.obfuscatedId}`">
          <i class='far fa-trash text-danger' />
        </button>
        <b-popover
          placement='left'
          :target="`delete-config-${row.obfuscatedId}`"
          custom-class='geogrids-popover p-1'
          triggers='hover'>
          <span class='text-danger'>Delete config</span>
        </b-popover>
      </div>
      <div v-else class="d-flex flex-column gap-2">
        <button @click="restoreConfig(row)" class='btn btn-link' :id="`restore-config-${row.obfuscatedId}`">
          <i class="far fa-sync text-danger" />
        </button>
        <b-popover
          placement='left'
          :target="`restore-config-${row.obfuscatedId}`"
          custom-class='geogrids-popover p-1'
          triggers='hover'>
          <span class='text-danger'>Restore config</span>
        </b-popover>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Tags from '../../components/tags'
import CategoriesList from '../../components/categories_list'
import Checkbox from '../../../components/checkbox'
import BtnCopyLinkToClipboard from '../../../components/copy_to_clipboard'

export default {
  components: {
    Tags, CategoriesList, Checkbox, BtnCopyLinkToClipboard
  },
  props: {
    row: { type: Object, required: true },
    apiConfigsPath: { type: String, required: true }
  },
  computed: mapState(['linda']),
  methods: {
    ...mapActions(['showConfig', 'showConfigsEditTab']),
    addTagToFilter(tag) {
      this.$emit('addTagToFilter', tag)
    },
    deleteConfig(row) {
      swal({
        text: 'This will also lead to deletion of all generated geogrids',
        icon: 'warning',
        buttons: ['Cancel', 'Delete all'],
        dangerMode: true
      }).then((result) => {
        if (result) {
          axios.delete(`${this.apiConfigsPath}/${row.obfuscatedId}`).then(() => {
            toastr.success('Config successfully deleted.')
            this.$emit('onDeleteRow')
          })
        }
      })
    },
    restoreConfig(row) {
      this.$emit('restoreGeogrids', [row.obfuscatedId])
    },
    onRowCheckToggle(bool) {
      this.$emit('onRowCheckToggle', { bool: bool, configId: this.row.obfuscatedId })
    }
  }
}
</script>
