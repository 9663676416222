<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
  <b-row>
    <b-col md='4' sm='12'>
      <div class='form-field'>
        <span class='caption'>Select business:</span>
        <multiselect
          label='text'
          :options='businessTypeOptions'
          :multiple='false'
          :close-on-select='true'
          :clear-on-select='false'
          :preserve-search='true'
          :show-labels='false'
          v-model='identifyBySelect' />
      </div>
    </b-col>
    <b-col md='8' sm='12' class='p-md-0'>
      <div class='form-field position-relative'>
        <span class='caption'>&nbsp;</span>
        <input
          v-show="identifyBy === 'google_search'"
          v-model='googleSearchQuery'
          type='text'
          ref='googleSearchInput'
          placeholder='Enter business name'>
        <input
          v-show="identifyBy === 'map_url'"
          :disabled="spinner"
          v-model='mapUrl'
          @change='onMapUrlInsert'
          type='text'
          placeholder='Enter map url'>
        <div class="identify-business-spinner" v-show="spinner">
          <i class="fa-duotone fa-spinner-third fa-spin" />
        </div>
        <input
          v-show="identifyBy === 'place_id'"
          v-model='placeIdInputValue'
          @change='onPlaceIdInsert'
          type='text'
          placeholder='Enter Place ID'>
        <small v-show="(identifyBy === 'place_id' || identifyBy === 'map_url')">
          Tracking business:
          <b v-show='businessName.length > 0'> {{ businessName }} </b>
          <span class='text-muted' v-show='businessName.length === 0'><b> not set </b></span>
        </small>
        <multiselect
          v-show="identifyBy === 'my_gmb'"
          label='text'
          placeholder='Enter GMB name, PID or store code'
          :options='myGmbLocations'
          :option-height='500'
          :multiple='false'
          :close-on-select='true'
          :clear-on-select='false'
          :preserve-search='true'
          :internal-search="false"
          :show-labels='false'
          @input='onGmbSelect'
          :loading='multiSelectSpinner'
          v-model='selectedMyGMB'
          @search-change='getGmbLocations'>
          <template slot='singleLabel' slot-scope='props'>
            {{ props.option.name }}
          </template>
          <template slot='option' slot-scope='props' style='height: 400px;'>
            <div class='option__desc'>
              <span class='option__title'>{{ props.option.name }}</span>
              <small class='option__small description'> {{ props.option.address || 'Service Area Business' }} </small>
              <small v-if='props.option.placeId' class='d-block option__small'> Place ID: {{ props.option.placeId }} </small>
              <small v-if='props.option.storeCode' class='d-block option__small'> Store code: {{ props.option.storeCode }} </small>
            </div>
          </template>
          <template slot='afterList'>
            <div v-observe-visibility='reachedEndOfList' />
          </template>
        </multiselect>
      </div>
    </b-col>
  </b-row>
</template>
<!-- eslint-enable vue/no-useless-template-attributes -->

<script>
import axios from 'axios'
import toastr from 'toastr'
import Multiselect from 'vue-multiselect'
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { ObserveVisibility } from 'vue-observe-visibility'
import snakeCaseKeys from 'snakecase-keys'
import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import axiosTransform from '../../../common/axios'

Vue.directive('observe-visibility', ObserveVisibility)

const NOT_GOOGLE_MAPS_URL = 'Please provide a URL to Google Maps GBP page.'

export default {
  name: 'IdentifyBusiness',
  components: { Multiselect },
  props: {
    allLocations: Array,
    gmbLocationsPath: String
  },
  data() {
    return {
      businessTypeOptions: [
        { text: 'My GMB', id: 'my_gmb' },
        { text: 'Google search', id: 'google_search' },
        { text: 'Place ID', id: 'place_id' },
        { text: 'Map url', id: 'map_url' }
      ],
      identifyBySelect: { text: 'My GMB', id: 'my_gmb' },
      selectedMyGMB: '',
      googleSearchQuery: '',
      mapUrl: '',
      placeId: '',
      placeIdInputValue: '',
      businessName: '',
      businessRemoteId: null,
      country: '',
      orgLocationOid: null,
      myGmbLocations: [],
      spinner: false,
      noLocationsFlag: false,
      multiSelectSpinner: false,
      searchTimeout: false,
      gmbSearchQuery: '',
      gmbPage: 1,
      businessAddress: '',
      businessLocationLat: null,
      businessLocationLng: null,
      placeIdVideos: {
        lv: 'https://youtu.be/EiSZrYSKl4g',
        lbm: 'https://youtu.be/msnm3fdkaRI'
      }
    }
  },
  computed: {
    ...mapState(['map']),
    identifyBy: {
      get() {
        if (this.identifyBySelect) return this.identifyBySelect.id
        return null
      },
      set(newValue) {
        this.identifyBySelect = this.businessTypeOptions.find((element) => element.id === newValue)
      }
    },
    placeIdError() {
      let error = 'Please provide correct Place ID.'
      const placeIdVideoUrl = this.placeIdVideos[Styxie.WLC.appCodename]
      if (this.placeIdVideoUrl) {
        error += ` Click <a href='${placeIdVideoUrl}' target='_blank'>
          <u>here</u></a> to watch the instructional video.`
      }
      return error
    }
  },
  watch: {
    identifyBySelect(newValue, oldValue) {
      this.$store.state.chosenTimeZone = null
      if (newValue) this.identifyBy = newValue.id
      else this.identifyBy = oldValue.id
      this.clearAll()
    }
  },
  mounted() {
    this.$autocompleteInput = this.$refs.googleSearchInput
    this.$autocomplete = new google.maps.places.Autocomplete(this.$autocompleteInput)
    this.$autocomplete.setFields(['name', 'address_components', 'place_id', 'geometry', 'formatted_address'])
    this.$autocomplete.addListener('place_changed', this.onAutocomplete)
    this.myGmbLocations = this.allLocations
    this.noLocationsFlag = !this.allLocations.length
  },
  methods: {
    ...mapMutations(['renderDummyGrid', 'hideMarkers']),
    reachedEndOfList(reached) {
      if (reached) {
        this.getGmbLocations(this.gmbSearchQuery, this.gmbPage + 1)
      }
    },
    renderForm(attrs) {
      if (attrs.mapUrl) {
        this.identifyBy = 'map_url'
      } else if (attrs.locationPath) {
        this.identifyBy = 'my_gmb'
        if (!this.selectedMyGMB || this.selectedMyGMB.id !== attrs.orgLocationOid) {
          this.$nextTick(() => {
            this.selectedMyGMB = {
              id: attrs.orgLocationOid,
              name: attrs.businessName,
              remoteId: attrs.remoteLocationId
            }
          })
        }
      } else {
        this.identifyBy = 'google_search'
      }

      this.$nextTick(() => {
        this.renderDummyGrid({ ...attrs, saveDisabledPoints: true })
        this.mapUrl = attrs.mapUrl
        this.businessName = attrs.businessName
        this.googleSearchQuery = attrs.businessName
        this.placeId = attrs.businessPlaceId
        this.country = attrs.businessCountry
        this.businessRemoteId = attrs.remoteLocationId
        this.orgLocationOid = attrs.orgLocationOid
        this.businessLocationLat = attrs.businessLocationLat
        this.businessLocationLng = attrs.businessLocationLng
        this.businessAddress = attrs.businessAddress
        if (!this.placeId && !this.businessLocationLat && !attrs.gridCenterLat) {
          this.findPlaceId()
          if (this.placeId) this.onPlaceIdInsert(this.placeId)
        }
      })
    },
    onMapUrlInsert() {
      this.spinner = true
      axios.get(
        '/api/google_places/fetch_by_url',
        {
          params: { url: this.mapUrl },
          paramsSerializer(json) { return qs.stringify(snakeCaseKeys(json, { deep: true })) },
          ...axiosTransform
        }
      ).then((res) => {
        const { businessData } = res.data
        this.businessName = businessData.name
        this.placeId = businessData.placeId
        this.country = businessData.country
        this.businessLocationLat = businessData.cidLat
        this.businessLocationLng = businessData.cidLng
        this.businessAddress = businessData.placeIdFormattedAddress
        this.renderDummyGrid({ gridCenterLat: this.businessLocationLat, gridCenterLng: this.businessLocationLng })
      }).catch((error) => {
        if (error.response.status === 422) {
          toastr.error(NOT_GOOGLE_MAPS_URL)
        } else {
          toastr.error(DEFAULT_ERROR_MESSAGE)
        }
        this.clearAll()
      }).finally(() => {
        this.spinner = false
      })
    },
    onPlaceIdInsert(data) {
      let text
      if (data.target) text = data.target.value
      else text = data

      const request = {
        placeId: text,
        fields: ['name', 'address_components', 'place_id', 'geometry', 'formatted_address']
      }
      const service = new google.maps.places.PlacesService(this.map)

      service.getDetails(request, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.businessName = place.name
          this.country = this.findCountry(place.address_components).short_name
          this.placeId = text
          this.businessLocationLng = place.geometry.location.lng()
          this.businessLocationLat = place.geometry.location.lat()
          this.businessAddress = place.formatted_address
          this.renderDummyGrid({ gridCenterLat: this.businessLocationLat, gridCenterLng: this.businessLocationLng })
        } else {
          toastr.error(this.placeIdError)
          this.clearAll()
        }
      })
    },
    findPlaceId() {
      const service = new google.maps.places.PlacesService(this.map)
      const biasCenter = new google.maps.LatLng(
        parseFloat(this.businessLocationLat),
        parseFloat(this.businessLocationLng)
      )
      const biasCircle = new google.maps.Circle({ center: biasCenter, radius: 1000 })
      const request = {
        locationBias: biasCircle,
        fields: ['place_id', 'formatted_address', 'name'],
        query: this.businessName
      }
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === 'OK' && this.businessName === results[0].name) {
          this.placeId = results[0].place_id
          this.businessAddress = results[0].formatted_address
        } else {
          this.placeId = null
        }
      })
    },
    onAutocomplete() {
      // vue does not listen event click on div with options
      // so, when user selects location by click (not enter)
      // this.businessInfo still contains only those symbols user typed
      this.googleSearchQuery = this.$refs.googleSearchInput.value

      const place = this.$autocomplete.getPlace()
      if (place.geometry) {
        const geo = place.geometry
        this.businessName = place.name
        this.placeId = place.place_id
        this.country = this.findCountry(place.address_components).short_name
        this.businessLocationLng = geo.location.lng()
        this.businessLocationLat = geo.location.lat()
        this.businessAddress = place.formatted_address
        this.renderDummyGrid({ gridCenterLat: this.businessLocationLat, gridCenterLng: this.businessLocationLng })
      } else {
        this.$autocompleteInput.value = ''
      }
    },
    onGmbSelect() {
      const business = this.selectedMyGMB
      if (!business) {
        this.clearAll()
        return
      }

      this.$store.state.chosenTimeZone = business.timeZone

      if (business.disconnected || business.disabled) {
        const state = business.disconnected ? 'disconnected' : 'disabled'
        toastr.warning(
          `${business.name} has been ${state}. Location data might be outdated and
          it may lead to irrelevant geogrid results.`,
          `Location is ${state}`
        )
      }
      this.businessName = business.name
      this.placeId = business.placeId
      this.businessLocationLng = business.coords.lng
      this.businessLocationLat = business.coords.lat
      this.businessAddress = business.address

      this.orgLocationOid = business.obfuscatedId
      if (this.businessLocationLat && this.businessLocationLng) {
        this.renderDummyGrid({ gridCenterLat: this.businessLocationLat, gridCenterLng: this.businessLocationLng })
      } else {
        this.renderDummyGrid({})
        if (!business.placeId) {
          toastr.error(
            `${business.name} never has been present on google maps or
            we have outdated data. We are not able generate geogrids.`,
            'No PlaceID'
          )
        } else {
          toastr.warning('We could not get business coordinates. Please, drag grid center to proper position', 'No address')
        }
      }
      this.getKeywords(business.keywordsPath)
      this.country = business.country
      this.businessRemoteId = business.remoteId
    },
    getKeywords(keywordsPath) {
      this.$emit('removeKeywords')
      axios.get(keywordsPath, {
        paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) },
        ...axiosTransform
      }).then((response) => {
        this.$emit('renderKeywords', response.data.keywords)
      })
    },
    findCountry(addresses) {
      if (addresses) return addresses.find((address) => address.types.includes('country'))
      return ''
    },
    clearAll() {
      this.selectedMyGMB = null
      this.businessName = ''
      this.country = ''
      this.placeId = ''
      this.placeIdInputValue = ''
      this.mapUrl = ''
      this.googleSearchQuery = ''
      this.businessLocationLat = ''
      this.businessLocationLng = ''
      this.businessAddress = ''
      this.businessRemoteId = null
      this.orgLocationOid = null
      this.hideMarkers()
      this.$emit('removeKeywords')
    },
    clearForm() {
      this.clearAll()
      this.identifyBy = 'my_gmb'
    },
    getBusinessInfo() {
      return {
        businessName: this.businessName,
        businessPlaceId: this.placeId,
        businessCountry: this.country,
        mapUrl: this.mapUrl,
        remoteLocationId: this.businessRemoteId,
        orgLocationOid: this.orgLocationOid,
        businessAddress: this.businessAddress,
        businessLocationLat: this.businessLocationLat,
        businessLocationLng: this.businessLocationLng
      }
    },
    getGmbLocations(search, page) {
      if (this.multiSelectSpinner) return
      if (this.searchTimeout) clearTimeout(this.searchTimeout)

      this.searchTimeout = setTimeout(() => {
        this.multiSelectSpinner = true
        this.gmbSearchQuery = search
        this.gmbPage = page || 1
        axios.get(this.gmbLocationsPath, {
          params: {
            search,
            page: this.gmbPage
          },
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) },
          ...axiosTransform
        }).then((result) => {
          if (page > 1) {
            this.myGmbLocations = this.myGmbLocations.concat(result.data.rows)
          } else {
            this.myGmbLocations = result.data.rows
          }
          this.multiSelectSpinner = false
        })
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
  .label {
    width: auto;
    display: inline-block;
    margin-top: 5px;
  }

  .fixed-border {
    height: 37px !important;
    border: 2px solid #ebebeb;
  }

  .form-control {
    font-size: 16px;
    padding: 0.92308rem 0.6rem;
  }

  .identify-business-spinner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2.2rem;

    i {
      animation-duration: 1s;
      animation-delay: 0ms;
      animation-iteration-count: infinite;
    }
  }
</style>
