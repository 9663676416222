<template>
  <div>
    <PreviewImages class="audit-content-sidebar__original-media"
      v-if="activeMedia"
      :media="[activeMedia]"
      :current-media-index="0"
      @close="activeMedia = null" />
    <div class="audit-content-sidebar"
      :class="{ active }">
      <div class="audit-content-sidebar__top">
        <div class="audit-content-sidebar__head">
          <div class="audit-content-sidebar__name">{{ business.name }}</div>
          <button class="audit-content-sidebar__close" @click="closeSidebar">Close <i class="far fa-xmark" /></button>
        </div>
        <div v-if="subtitle" class="audit-content-sidebar__subtitle">{{ subtitle }}</div>
        <Tabs
          ref="tabs"
          class="audit-content-sidebar__tabs"
          :list="tabs.list"
          :active-tab="tabs.activeTab"
          @setActiveTab="setActiveTab" />
        <div class="audit-content-sidebar__filter" :class="{
          'audit-content-sidebar__filter--hide': hideFilters
        }">
          <span v-for="(filter, index) in activeFilters"
            @click="setActiveFilter(index)"
            class="audit-content-sidebar__tag"
            :class="{ active: index === filters.activeFilterIndex }"
            :key="`star${index}`">
            <template v-if="filter.icon">
              <i v-for="iconIndex in filter.icon.count" :class="filter.icon.cssClass" :key="iconIndex" />
            </template>
            {{ filter.text }}
          </span>
        </div>
      </div>
      <div class="audit-content-sidebar__list"
        :class="{'scroll ver': !hideContent}"
        ref="contentContainer"
        @scroll="handleInfiniteScroll">
        <div
          class="audit-content-sidebar__content-spinner"
          :class="{ active: hideContent || hideFilters }">
          <Spinner />
        </div>
        <transition-group name="fade-appear" tag="div" class="audit-content-sidebar__wrapper"
          v-if="shownCards && shownCards.length"
          :class="{
            'audit-content-sidebar__wrapper--hide': hideContent
          }">
          <component v-for="(card, index) in shownCards"
            class="audit-content-sidebar__card"
            :is="`card-${animationEnd.activeTab}`"
            :key="`content-card-${animationEnd.activeTab}-${index}`"
            :content="card"
            @openGallery="setActiveMedia" />
          <div
            class="audit-content-sidebar__spinner"
            key="audit-content-sidebar__spinner"
            :class="{ active: showSpinner }">
            <Spinner />
          </div>
        </transition-group>
        <div v-else-if="!hideContent && !showSpinner" class="audit-content-sidebar__no-results">
          <p>Unfortunately,</p>
          <span>there is no data matching your request.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import hotkeys from 'hotkeys-js'
import axios from 'axios'

import axiosTransform from '../../../common/axios'

import Spinner from '../../components/spinner'
import Tabs from '../../components/reusable_tabs.vue'

import CardReviews from './blanks/card_review.vue'
import CardMedia from './blanks/card_media.vue'
import CardPosts from './blanks/card_post.vue'
import CardServices from './blanks/card_service.vue'
import CardQa from './blanks/card_qa.vue'
import PreviewImages from '../../gallery/preview_images.vue'

import contentSidebarGalleryMixin from './content_sidebar_gallery_mixin'

const REVIEWS_TEXT = 'Reviews'
const MEDIA_TEXT = 'Media'
const POSTS_TEXT = 'Posts'
const SERVICES_TEXT = 'Services'
const QA_TEXT = 'Q&A'

const PER_PAGE = 10

export default {
  components: {
    CardReviews,
    CardMedia,
    CardPosts,
    CardServices,
    CardQa,
    Tabs,
    Spinner,
    PreviewImages
  },
  props: {
    business: { type: Object, required: true },
    reportId: { type: String, required: true },
    contentType: { type: String, required: false },
    subtitle: { type: String, required: false, default: null },
    isPublic: { type: Boolean, required: true }
  },
  mixins: [contentSidebarGalleryMixin],
  data: () => ({
    active: false,
    showSpinner: false,
    hideFilters: false,
    hideContent: false,
    tabs: {
      activeTab: 'media',
      list: {
        reviews: REVIEWS_TEXT,
        media: MEDIA_TEXT,
        posts: POSTS_TEXT,
        services: SERVICES_TEXT,
        qa: QA_TEXT
      }
    },
    animationEnd: {
      activeTab: 'media',
      activeFilterIndex: 0
    },
    fetchTimeout: null,
    shownCards: [],
    totalCount: null,
    filters: {
      activeFilterIndex: 0,
      // if I remove this list from data computed properties do not work
      list: {
        reviews: [
          {
            text: 'All',
            value: ''
          },
          {
            text: '5 stars',
            value: 5,
            icon: {
              cssClass: 'fas fa-star',
              count: 5
            }
          },
          {
            text: '4 stars',
            value: 4,
            icon: {
              cssClass: 'fas fa-star',
              count: 4
            }
          },
          {
            text: '3 stars',
            value: 3,
            icon: {
              cssClass: 'fas fa-star',
              count: 3
            }
          },
          {
            text: '2 stars',
            value: 2,
            icon: {
              cssClass: 'fas fa-star',
              count: 2
            }
          },
          {
            text: '1 star',
            value: 1,
            icon: {
              cssClass: 'fas fa-star',
              count: 1
            }
          }
        ],
        media: [
          {
            text: 'All',
            value: ''
          },
          {
            text: 'Image',
            value: 1
          },
          {
            text: 'Video',
            value: 0
          },
          {
            text: 'Street view',
            value: 2
          }
        ],
        posts: [
          {
            text: 'All',
            value: ''
          },
          {
            text: 'Standard',
            value: 'standard'
          },
          {
            text: 'Offers',
            value: 'offer'
          },
          {
            text: 'Events',
            value: 'event'
          }
        ],
        services: [
          {
            text: 'All',
            value: ''
          },
          {
            text: 'Matched',
            value: true
          }
        ],
        qa: [
          {
            text: 'All',
            value: ''
          },
          {
            text: 'Answered',
            value: 'true'
          },
          {
            text: 'Unanswered',
            value: 'false'
          }
        ]
      }
    }
  }),
  methods: {
    setActiveTab(tabName) {
      this.tabs.activeTab = tabName
    },
    setActiveFilter(filterIndex) {
      this.filters.activeFilterIndex = filterIndex
    },
    formatServices(services) {
      const existentCategories = this.shownCards.reduce((mem, card) => {
        if (card.category) {
          mem.add(card.category)
        }

        return mem
      }, new Set())

      return services.map(({ category, ...service }) => {
        if (!existentCategories.has(category)) {
          existentCategories.add(category)
          return {
            category,
            ...service
          }
        }

        return service
      })
    },
    fetchData(paginate) {
      // todo: remove, this is for mocking purposes
      /* this.hideContent = false
      this.hideFilters = false
      this.shownCards =[
        {
          body: 'i have a question',
          author: {
            name: 'author name'
          },
          when: 'today',
          answers: [
            {
              id: 'test',
              body: 'answer!',
              author: {
                name: 'answered author name'
              },
              when: 'tomorrow',
            },
            {
              id: 'test2',
              body: 'answer2!',
              author: {
                name: 'answered author name'
              },
              when: 'tomorrow',
            },
          ]
        },
        {
          body: 'i have a question #2',
          author: {
            name: 'author name'
          },
          when: 'today',
          answers: []
        }
      ]
      return */
      this.$nextTick(() => {
        if (paginate) {
          this.showSpinner = true
        }

        clearTimeout(this.fetchTimeout)

        this.fetchTimeout = setTimeout(() => {
          let page = 1

          if (!paginate) {
            this.shownCards = []
            this.totalCount = null
          } else {
            page = Math.ceil(this.shownCards.length / PER_PAGE) + 1
          }

          axios.get(`${this.fetchEndpoint}`, {
            params: {
              ...this.query,
              per_page: PER_PAGE,
              page
            },
            ...axiosTransform
          })
            .then((response) => {
              // if we will need other formatters in the future, we can add them
              // ask if we really need this, but i kinda feel like we do
              const formatter = this[`format${this.tabs.list[this.tabs.activeTab]}`]
              const data = formatter ? formatter(response.data) : response.data

              this.shownCards.push(...data)
              this.totalCount = parseInt(response.headers['total-count'], 10)
            })
            .finally(() => {
              this.showSpinner = false

              setTimeout(() => {
                this.hideContent = false
                this.hideFilters = false
              }, 300)
            })
        }, 1000)
      })
    },
    handleInfiniteScroll() {
      if (this.showSpinner || !this.$refs.contentContainer) {
        return
      }

      const contentContainerHeight = this.$refs.contentContainer.getBoundingClientRect().height
      const { scrollTop, scrollHeight } = this.$refs.contentContainer
      const offset = 100

      const remainingScrollPixels = scrollHeight - scrollTop - contentContainerHeight
      const scrolledDownEnough = remainingScrollPixels <= offset

      // !this.totalCount won't work because if we have 0 results it will think it has something to fetch
      const hasContentToFetch = this.totalCount === null || this.shownCards.length < this.totalCount
      const shouldFetch = scrolledDownEnough && hasContentToFetch

      if (!shouldFetch) {
        return
      }

      this.fetchData(true)
    },
    closeSidebar() {
      this.active = false
      setTimeout(() => {
        this.$emit('close')
      }, 700)
    },
    fixContainerScrollPosition() {
      // overflow-anchor didn't work, so I had to do this, otherwise
      // we will have totally wrong scroll position after each fetch
      if (!this.$refs.contentContainer) {
        return
      }

      this.$refs.contentContainer.scrollTo({
        top: 0
      })
    },
    handleEscKeyPress() {
      if (this.activeMedia) {
        this.activeMedia = null
      } else {
        this.closeSidebar()
      }
    },
    setActiveMedia(media) {
      const { type, originalUrl: url } = media
      this.activeMedia = {
        type,
        url
      }
    }
  },
  computed: {
    activeFilters() {
      return this.filters.list[this.animationEnd.activeTab]
    },
    activeFilter() {
      return this.activeFilters[this.animationEnd.activeFilterIndex]
    },
    businessIdKey() {
      return this.business.isCompetitor ? 'competitor_id' : 'location_id'
    },
    query() {
      const queryObject = {
        [this.businessIdKey]: this.business.id
      }

      if (this.activeFilter.value !== '') {
        queryObject[this.queryKeys[this.tabs.activeTab]] = this.activeFilter.value
      }

      return queryObject
    },
    endpoints() {
      const prefix = `${this.isPublic ? '/stats' : ''}/api/audit_reports/${this.reportId}`
      return {
        media: `${prefix}/media`,
        posts: `${prefix}/posts`,
        reviews: `${prefix}/reviews`,
        services: `${prefix}/gbp_services`,
        qa: `${prefix}/qnas`
      }
    },
    fetchEndpoint() {
      return this.endpoints[this.tabs.activeTab]
    }
  },
  created() {
    this.tabs.activeTab = this.contentType

    this.queryKeys = {
      reviews: 'stars',
      media: 'media_type',
      posts: 'post_type',
      services: 'matched',
      qa: 'answered'
    }
  },
  mounted() {
    // this.$nextTick doesn't work for some reason
    setTimeout(() => {
      this.active = true
      hotkeys('esc', this.handleEscKeyPress)
    })
  },
  destroyed() {
    hotkeys.unbind('esc', this.handleEscKeyPress)
  },
  watch: {
    'tabs.activeTab': {
      immediate: true,
      handler(newValue) {
        this.fixContainerScrollPosition()

        this.$nextTick(() => {
          this.hideContent = true
          this.hideFilters = true
        })

        setTimeout(() => {
          this.filters.activeFilterIndex = 0
          this.fetchData()
          setTimeout(() => {
            this.animationEnd.activeTab = newValue
          }, 300)
        }, 300)
      }
    },
    'filters.activeFilterIndex': {
      handler(newValue) {
        this.fixContainerScrollPosition()

        this.$nextTick(() => {
          this.hideContent = true
        })

        setTimeout(() => {
          this.animationEnd.activeFilterIndex = newValue
        }, 300)
      }
    },
    activeFilter() {
      this.$nextTick(this.fetchData)
    },
    contentType(newValue) {
      this.$nextTick(() => {
        this.tabs.activeTab = newValue
      })
    },
    business() {
      this.fixContainerScrollPosition()

      this.$nextTick(() => {
        this.hideContent = true
      })

      setTimeout(() => {
        this.fetchData()
      }, 300)
    }
  }
}
</script>
