<template>
  <b-row v-if='geogrid.obfuscatedId'
    class='d-flex gap-1 inmap bottom'
    :class="competitorGeogridIsSelected ? 'horizontal-center' : 'left'">
    <div class='geogrid-info'>
      <div class='label'>Created:</div>
      <div class='data'>{{ geogrid.createdAtFormatted }}</div>
    </div>
    <div class='geogrid-info'>
      <div class='label'>Google region:</div>
      <div class='data'>
        <span v-if='geogrid.attrs.localLanguageEnabled && geogrid.attrs.businessCountry'>{{ geogrid.attrs.businessCountry }}</span>
        <span v-else>global</span>
      </div>
    </div>
    <div class='geogrid-info'>
      <div class='label'>Grid:</div>
      <div class='data'>{{ geogrid.attrs.gridFormatted }}</div>
    </div>
    <div class='d-flex align-items-center gap-2 geogrid-info'>
      <div>
        <div class='label'>AGR:</div>
        <div class='data'>{{ geogrid.agr }}</div>
      </div>
      <div>
        <div class='label'>ATGR:</div>
        <div class='data'>{{ geogrid.atgr }}</div>
      </div>
      <div>
        <div class='label'>SoLV:</div>
        <div class='data'>{{ solvPercent }}</div>
      </div>
      <div>
        <i class="far fa-info-circle text-primary" id="ranks-explanation" />
        <b-popover
          placement="top"
          target="ranks-explanation"
          custom-class="ranks-explanation-popover"
          triggers="hover">
          <div class="ranks-explanation">
            <div>
              <b class="color-dark font-size-14 font-weight-semi-bold">AGR</b>
              <p class="text-muted font-size-12 m-0">
                Average Grid Rank. Only nodes with ranks equal to "20" and less considered.
              </p>
            </div>
            <div>
              <b class="color-dark font-size-14 font-weight-semi-bold">ATGR</b>
              <p class="text-muted font-size-12 m-0">
                Average Total Grid Rank. All nodes considered. The "21" value is used for nodes with ranks over "20".
              </p>
            </div>
            <div>
              <b class="color-dark font-size-14 font-weight-semi-bold">SoLV</b>
              <p class="text-muted font-size-12 m-0">
                Share of Local Voice. Average value among nodes having ranks equal to "3" and less.
              </p>
            </div>
          </div>
        </b-popover>
      </div>
    </div>
    <div v-if="!competitorGeogridIsSelected && !isLive" class="d-flex align-items-center gap-2 geogrid-info">
      <div class="d-flex align-items-center gap-1 font-size-14 text-muted">
        <img :src="require('top3_marker_first.svg')" alt="">
        1st Time Top 3
      </div>
      <div class="d-flex align-items-center gap-1 font-size-14 text-muted">
        <img :src="require('top3_marker_second.svg')" alt="">
        2nd+ Time Top 3
      </div>
    </div>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'GeogridInfo',
  props: {
    competitorGeogridIsSelected: Object
  },
  computed: {
    ...mapState(['geogrid', 'pageState']),
    isLive() {
      return this.pageState === 'live'
    },
    solvPercent() {
      if (Number.isNaN(parseFloat(this.geogrid.solv))) return 'N/A'

      return `${parseInt(this.geogrid.solv * 100, 10)}%`
    }
  }
}
</script>
