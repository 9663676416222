<template>
  <form @submit.prevent="submit">
    <p>
      You will receive a JSON payload every time a scheduled geogrid is created in your organization,
      including individual and bulk on-demand runs.
    </p>
    <div class="form-group row align-items-center mb-3">
      <div class="col-12 col-sm-3 text-sm-right">
        Webhook URL
      </div>
      <div class="col-12 col-lg-4 col-md-6 col-sm-9 form-field">
        <input :disabled="isRequesting" type="text" v-model="webhookUrlModel">
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-3" />
      <div class="col-12 col-lg-4 col-md-6 col-sm-9">
        <div class="d-flex flex-wrap justify-content-between gap-3">
          <button :disabled="isRequesting" type="button" class="btn rounded" @click="testWebhook">Test webhook</button>
          <button :disabled="isRequesting" class="btn btn-primary rounded">Save webhook</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axiosTransform from 'common/axios'
import toastr from 'toastr'

export default {
  props: {
    webhookUrl: { type: String, default: '' },
    webhooksApiUrl: { type: String, required: true },
    testWebhooksApiUrl: { type: String, required: true }
  },
  data() {
    return {
      webhookUrlModel: this.webhookUrl,
      isRequesting: false
    }
  },
  methods: {
    submit() {
      this.isRequesting = true
      this.saveWebhook()
        .then(() => {
          toastr.success('Webhook URL is saved successfully')
        })
        .catch((res) => {
          this.handleError(res.response)
        })
        .finally(() => {
          this.isRequesting = false
        })
    },
    saveWebhook() {
      return axios.patch(this.webhooksApiUrl, {
        organization: {
          webhookUrl: this.webhookUrlModel
        }
      }, axiosTransform)
    },
    testWebhook() {
      if (!this.webhookUrlModel) {
        toastr.error('Please specify the Webhook URL and try again.')
        return
      }
      this.isRequesting = true
      this.saveWebhook()
        .then(() => {
          axios.put(this.testWebhooksApiUrl, {}, axiosTransform)
            .then(() => {
              toastr.success('Webhook URL is tested successfully.')
            })
            .catch(({ response }) => {
              this.handleError(response)
            })
            .finally(() => {
              this.isRequesting = false
            })
        })
        .catch(({ response }) => {
          this.handleError(response)
          this.isRequesting = false
        })
    },
    handleError(response) {
      const { errorMessage } = response.data
      Object.keys(errorMessage).forEach((key) => {
        toastr.error(`${this.capitalize(this.convertCamelCaseToWords(key).toLowerCase())}${errorMessage[key].join(', ')}`)
      })
    },
    capitalize(input) {
      return input.charAt(0).toUpperCase() + input.slice(1)
    },
    convertCamelCaseToWords(input) {
      if (input === 'test') return ''
      return `${input.replace(/([a-z])([A-Z])/g, '$1 $2')} `
    }
  }
}
</script>
