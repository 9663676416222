<template>
  <div class="geobooster-widgets-edit-form">
    <div
      v-for="(line, lineIndex) in appearanceStepLayout"
      class="geobooster-widgets-edit-form__line"
      :class="{ 'flex-xlg-row': line.length > 1 }" :key="`line${lineIndex}`">
      <Component
        v-for="field in line"
        v-model="field.value"
        :key="field.name"
        :is="field.component"
        :values="field.values"
        :error="field.error"
        :error-text="field.errorText"
        :type="field.type ? field.type : 'text'"
        :label="field.label"
        :name="field.name"
        :disabled="field.disabled"
        :default-value="field.defaultValue"
        @input="field.error = false"
        @blur="$emit('triggerValidate')"
        @setError="$emit('setError', { error: $event.error, errorText: $event.errorText, field })" />
    </div>
  </div>
</template>

<script>
import CustomInput from '../../../components/custom_input'
import CustomSelect from '../../../components/custom_select'
import Radio from '../../../components/radio_panels'
import ColorPicker from '../../../components/color_picker'

export default {
  components: {
    CustomInput,
    CustomSelect,
    Radio,
    ColorPicker
  },
  props: {
    fields: {
      type: Object,
      required: true
    }
  },
  computed: {
    appearanceStepLayout() {
      const cardMomentConfig = [
        [
          this.fields.momentCardLayout
        ]
      ]
      // If we select "slider view" card layout in configurator, "preview size" select will make no sense so we
      // only show it when moment card layout is "default"
      if (this.fields.momentCardLayout.value === 'tile') {
        cardMomentConfig.push([
          this.fields.momentCardCssClass
        ])
      }
      return [
        [
          this.fields.theme
        ],
        [
          this.fields.widgetLayout
        ],
        ...cardMomentConfig,
        [
          this.fields.momentListLayout
        ],
        [
          this.fields.font
        ],
        [
          this.fields.backgroundColor,
          this.fields.borderColor
        ],
        [
          this.fields.momentCardBgColor
        ],
        [
          this.fields.customerNameColor,
          this.fields.textColor
        ],
        [
          this.fields.dateColor,
          this.fields.dateFormat
        ],
        [
          this.fields.categoryTextColor,
          this.fields.categoryBgColor
        ],
        [
          this.fields.mapMarkerColor
        ]
      ]
    }
  }
}
</script>
